import React from "react";
import {Container} from "reactstrap";
import {AlertOnErrors} from "../../shared/alertOnErrors";
import {NameValueBox} from "../../shared/nameValueView/NameValueBox";
import {PageSection} from "../../shared/pageSection/PageSection";
import {useMyClaim} from "../../api/main/claims/useMyClaim";
import {useMatchingDataFormDatas} from "../../api/main/dataFormDatas/useMatchingDataFormDatas";
import {LoadingComponent} from "../shared/LoadingComponent";

export function MyDevice(props: {claimId?: string}){
    const {data: {item}, isLoading, errors: loadingErrors} = useMyClaim({id: props.claimId});

    const requiredDatas = [
        "Manufacturer",
        "Model",
        "Colour",
        "Storage",
        "Memory Size",
        "Serial Number",
        "IMEI",
    ]

    const specs = useMatchingDataFormDatas(item?.dataFormData, (data) => requiredDatas.includes(data.name));

    // TODO: use category level descriptions for this!
    const deviceName = specs !== undefined ?
        `${(specs["Manufacturer"]?.textValue ?? "")} ${specs["Model"]?.textValue ?? ""}`
        : "";

    return (
        <PageSection className={"my-device"}>
            <AlertOnErrors errors={[loadingErrors]} />
            <LoadingComponent isLoading={isLoading}>
                <h1>My Device</h1>
            </LoadingComponent>
            {
                // if isLoading, display placeholders
                isLoading && (
                    <Container fluid>
                        <Container className={"device-summary"}>
                            <NameValueBox placeholder/>
                            <NameValueBox placeholder/>
                        </Container>
                        <div className={"device-details"}>
                            <NameValueBox placeholder/>
                            <NameValueBox placeholder />
                            <NameValueBox placeholder />
                            <NameValueBox placeholder />
                            <NameValueBox placeholder />
                            <NameValueBox placeholder />
                            <NameValueBox placeholder />
                        </div>
                    </Container>
                )
            }
            {
                // when loaded, show actual data
                !isLoading && item && specs && (
                    <Container className={"device-container"} fluid>
                        <Container className={"device-summary"}>
                            <NameValueBox name={"Warranty Provider"} value={item.contract?.customer ?? ""}/>
                            <NameValueBox name={"Device Type"} value={item.equipmentType?.name} displayEmpty={false}/>
                        </Container>
                        <div className={"device-details"}>
                            {
                                item?.state && item?.state.name === "Collection" ? (
                                    <div className={"no-data-message"}>
                                        {"We are waiting to inspect your device. Come back later to view your device specs."}
                                    </div>
                                ) : (
                                    <>
                                        {
                                            Object.entries(specs).map(([name, data], i) => {
                                                    return (
                                                        <NameValueBox key={i} name={name} value={data?.textValue}
                                                                      size={"sm"} displayEmpty={false}/>
                                                    )
                                                }
                                            )}
                                    </>
                                )
                            }

                        </div>
                    </Container>

                )}
        </PageSection>
    )
}
