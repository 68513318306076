import {useParams} from "react-router";


/**
 * Shortcut function to get the current claim id
 * from the url.
 */
export function useCurrentClaimId(): string | undefined {
    // if we are calling from inside a route, we can get it from use params
    let {id} = useParams();
    return id;
}