import React from "react";


export function useFormattedAddressCallback() {

    return React.useCallback((address?: string) => {
        if (!address) return '';

        const matches = address.match(/([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/m)
        if (!matches) return address;

        const postCode = matches[0];
        if (!postCode) return address;

        let newAddress = '';
        const addressLines = address.replace(postCode, "").split('\n');

        addressLines.forEach(line => {
            newAddress += line.split(" ")
                .map(word => word.charAt(0).toUpperCase() + word.toLowerCase().substr(1))
                .join(' ')
                .concat('\n');
        })

        return `${newAddress}\n${postCode.toUpperCase()}`;

    }, [])

}