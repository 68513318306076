import JSZip from "jszip";
import {useAsyncCallback} from "react-use-async-callback";
import {AsyncActionStatus} from "../abstractStore";


export interface ZipOptions {
    files: {[key: string]: string},
    folderName?: string
}


/**
 * Create a zip file blob from a collection of files, where
 * key is the full file name and value is the base64 encoded data
 */
export function useZipBlobCallback(): [(options: ZipOptions) => Promise<Blob|null>, AsyncActionStatus] {

    const [action, {isExecuting, errors}] = useAsyncCallback(async (options: ZipOptions) => {
        const {files, folderName = "content"} = options;
        if (!files) return null;

        // create a new zip folder
        const zip = new JSZip();
        const folder = zip.folder(folderName);
        if (folder === null) throw new Error("Could not create a zip folder!");

        // go through our file collection and add them to the zip folder
        Object.entries(files).filter(f => (f !== null)).forEach(([key, data]) => {
            folder?.file(key, data, {base64: true})
        })

        // generate a blob representation of the zipped data
        const result = await folder.generateAsync({type: "blob"})
        return result;
    }, []);

    return [action, { isExecuting, errors }];

}