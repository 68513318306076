import React from 'react';
import ReactPDF, {Document, Font, Image, Page, StyleSheet, Text, View} from "@react-pdf/renderer";
import {PageHeader, PdfTable, PdfTableRow} from "../shared/PdfComponents";
import {ReportModel} from "./Report";


Font.register({
  family: "Roobert",
  fonts: [
    {
      src: `${process.env.PUBLIC_URL}/fonts/Roobert-Regular.ttf`,
      fontStyle: "normal",
    },
    {
      src: `${process.env.PUBLIC_URL}/fonts/Roobert-Regular.ttf`,
      fontStyle: "semi-bold",
      fontWeight: 700,
    },
    {
      src: `${process.env.PUBLIC_URL}/fonts/Roobert-Bold.ttf`,
      fontStyle: "bold",
      fontWeight: 700
    }
  ]
})


// pdf styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFF',
    fontFamily: "Roobert",
    fontSize: "8pt",
    maxWidth: "100%",
    display: "flex",
  },
  sectionHeader: {
    fontStyle: "bold",
    fontSize: "10pt",
    width: "100%",
    textAlign: "left",
    marginTop: 20,
    marginBottom: 15,
  },
  tableParent: {
    paddingHorizontal: 60,
    paddingVertical: 20,
  },
  darkSection: {
    maxWidth: "100%",
    backgroundColor: "#fafafa",
  },
  lightSection: {
    maxWidth: "100%",
    backgroundColor: "#FFF"
  },
  tableHeader: {
    width: "100pt",
    minHeight: "15pt",
    color: "#505050",
    fontStyle: "semi-bold",
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  tableCell: {
    width: "130pt",
    minHeight: "15pt",
    fontStyle: "normal",
    display: "flex",
    flexGrow: 2,
    justifyContent: "center",
    alignItems: "flex-start",
  },
  darkCell: {
    padding: 5,
    backgroundColor: "#fafafa",
    border: "3pt solid #fff",
  },
  lightCell: {
    padding: 5,
    backgroundColor: "#fff",
    border: "3pt solid #fafafa",
  },
  leftTable: {
    width: "50%",
    flex: 1,
  },
  rightTable: {
    width: "50%",
    flex: 1,
  }
});



export const ReportRow = (props: {header: string, value?: string, style?: ReactPDF.Style, displayEmpty?: boolean}) => {
  const {header, value, style = {}, displayEmpty = false} = props;

  const display = displayEmpty || value;

  if (!display) return (<></>);

  return (
      <PdfTableRow>
        <View style={{...styles.tableHeader, ...style}}>
          <Text style={{flexBasis: 25}}>{header}</Text>
        </View>
        <View style={{...styles.tableCell, ...style}}>
          <Text>{value}</Text>
        </View>
      </PdfTableRow>
  )
}


export const PdfReport = (props: ReportModel) => {

  const {
    claimNumber,
    policyNumber,
    jobReference,
    inspectionStart,
    inspectionEngineer,
    endUserName,
    endUserAddress,
    endUserPhone,
    accessories,
    equipmentType,
    deviceInformation,
    inspectionInformation
  } = props;



  return (
      <Document title={`Claim ${claimNumber} - Inspection Report`}>
        <Page size={"A4"} style={styles.page}>
          <PageHeader style={{paddingHorizontal: 60}}>
            <View style={{ flexDirection: "row", flexGrow: 2, textAlign: "left", width: "60%" }}>
              <Text style={{ marginTop: "8pt" }}>{"Inspection Report"}</Text>
            </View>
            <View style={{ flexGrow: 1, alignItems: "flex-end" }}>
              <Image
                  style={{ height: "25pt", width: "98pt", marginTop: "8pt" }}
                  src={`${process.env.PUBLIC_URL}/img/greenbridge-logo-white.png`} />
            </View>
          </PageHeader>
          <View style={styles.tableParent}>
            <ReportRow header={"Customer"} value={endUserName} style={styles.darkCell}/>
            <ReportRow header={"Address"} value={endUserAddress} style={styles.darkCell}/>
            <ReportRow header={"Contact"} value={endUserPhone} style={styles.darkCell}/>
          </View>
          <View style={{...styles.tableParent, ...styles.darkSection}}>
            <PdfTableRow>
              <PdfTable style={{flexGrow: 1}}>
                <ReportRow header={"Greenbridge Ref"} value={claimNumber} style={styles.lightCell}/>
                <ReportRow header={"Policy Number"} value={policyNumber} style={styles.lightCell}/>
                <ReportRow header={"Job Number"} value={jobReference} style={styles.lightCell}/>
              </PdfTable>
              <PdfTable style={{flexGrow: 1, flexDirection: "column", justifyContent: "flex-start"}}>
                {inspectionStart && (
                    <ReportRow
                        header={"Inspection Date"}
                        value={inspectionStart?.format("DD/MM/YYYY HH:mm")}
                        style={{...styles.lightCell, height: 27}}
                    />
                )}
                <ReportRow header={"Inspection Engineer"}
                           value={inspectionEngineer}
                           style={{...styles.lightCell, height: 27}}
                />
                <View style={{flexGrow: 2, minHeight: 28}}/>
              </PdfTable>
            </PdfTableRow>
            <View style={styles.sectionHeader}>
              <Text>{"Device Accessories Present"}</Text>
            </View>
            <View style={{width: "100%"}}>
              <PdfTable style={{width: "100%"}}>
                <PdfTableRow>
                  <View style={{...styles.tableCell, ...styles.lightCell, padding: 15}}>
                    <Text>{accessories && accessories.length ?
                        accessories.map((item, _) => {
                          return `•  ${item}\n`;
                        })
                        : "N/A"}</Text>
                  </View>
                </PdfTableRow>
              </PdfTable>
            </View>
            <View style={styles.sectionHeader}>
              <Text>{"Device Information"}</Text>
            </View>
            <ReportRow header={"EquipmentType"} value={equipmentType} style={styles.lightCell}/>
            <ReportRow header={"Colour"} value={deviceInformation["Colour"]?.value} style={styles.lightCell}/>
            <ReportRow header={"Manufacturer"} value={deviceInformation["Manufacturer"]?.value} style={styles.lightCell}/>
            <ReportRow header={"Serial Number"} value={deviceInformation["Serial Number"]?.value} style={styles.lightCell}/>
            <ReportRow header={"IMEI"} value={deviceInformation["IMEI"]?.value} style={styles.lightCell}/>
            <ReportRow header={"Memory Size"} value={deviceInformation["Memory Size"]?.value} style={styles.lightCell}/>
            <ReportRow header={"RAM"} value={deviceInformation["RAM"]?.value} style={styles.lightCell}/>
            <View style={styles.sectionHeader}>
              <Text>{"Inspection Information"}</Text>
            </View>
            <ReportRow header={"Fault Diagnosis"} value={inspectionInformation["Inspection Type 1"]?.value} style={styles.lightCell}/>
            <ReportRow header={"Fault Diagnosis 2"} value={inspectionInformation["Inspection Type 2"]?.value} style={styles.lightCell}/>
            <ReportRow header={"Fault Diagnosis 3"} value={inspectionInformation["Inspection Type 3"]?.value} style={styles.lightCell}/>
            <View style={{...styles.sectionHeader, fontSize: 9}}>
              <Text>{"Terms & Conditions"}</Text>
            </View>
            <View style={{fontSize: 6}}>
              <Text>{"Greenbridge Technology is a trading name of Laptop Power NW Ltd (Company 079539), " +
              "who have been instructed on behalf of the Insurance Company to handle your insurance claim. " +
              " Greenbridge Technology will take care to look after your personal data, however, " +
              "please ensure that all data has been fully backed up before your unit is collected. " +
              "If your machine is repairable any parts/components required will be ordered and the repair completed.  " +
              "Payment of your excess on your policy will be required before a repair can take place.  " +
              "If the unit is beyond economical repair.  " +
              "The Insurance Company will replace the unit with a new equivalent specification machine," +
              " based on the specification listed above."}</Text>
            </View>
          </View>
        </Page>
      </Document>
  )
}
