import './mainContainer.scss';

import * as React from 'react';
import {MainContainer, MainContainerProps} from "./MainContainer";
import {useCurrentClaimId} from "../../api/main/claims/useCurrentClaimId";
import {Redirect, Route} from "react-router";
import {useValidateClaimId} from "../../api/main/claims/useValidateClaimId";
import {Notification} from "./Notification";
import {PasscodeEntry} from "../passcodeEntry/PasscodeEntryModal";
import {ICloudModal} from "./ICloudModal";
import {ClaimStateNotification} from "./ClaimStateNotification";


/**
 * Checks that a valid claim id is in the address bar
 * before rendering its children. Otherwise redirects to
 * Track My Claim
 */
export const TrackedClaimContainer = (props: MainContainerProps) => {
    // todo: this can be better. we are manually hiding notification on submit
    const [, setState] = React.useState<any>();


    function handleUpdate() {
        //passing empty object will re-render the component
        setState({});
    }

    const { className, children } = props;

    // get claim id in the query string
    const currentClaimId = useCurrentClaimId();

    // get validation query function
    const {claimId, isLoading, called} = useValidateClaimId(currentClaimId);

    // show nothing until we validated the claim
    if ((isLoading || !called) && claimId === undefined){
        return (<></>)
    }

    if (called && claimId && currentClaimId === claimId) {

        return (
            <MainContainer className={className} {...props}>
                <ClaimStateNotification claimId={claimId} stateName={"Sent for External Repair"}>
                    <>
                        {"Your device is currently with an Authorised Apple Service Provider and is subject to Apple's service level agreements. " +
                        "In some cases this may be a little longer than the estimated date above"}
                    </>
                </ClaimStateNotification>
                <Notification claimId={claimId} />
                {children}
                <Route path={"/:page/:id/submit-passcode"} component={() => <PasscodeEntry onSubmitCallback={() => handleUpdate()}/>}/>
                <Route path={"/:page/:id/remove-icloud"} component={() => <ICloudModal onSubmitCallback={()=>handleUpdate()}/>}/>
            </MainContainer>
        );

    } else {
        const redirect = {
            pathname: "/track",
            search:`?returnUrl=${encodeURIComponent(window.location.href)}`
        }
        return <Redirect to={redirect} />
    }

};
