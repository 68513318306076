import React from "react";
import ReactPDF, {StyleSheet, View} from '@react-pdf/renderer';


// pdf styles
export const pdfStyles = StyleSheet.create({
    table: {
        width: "auto",
    },
    body: {
        margin: "40px",
    },
    pageHeader: {
        margin: 0,
        backgroundColor: "#67beb0",
        fontStyle: "bold",
        maxWidth: "100%",
        flexDirection: "row",
        color: "white",
        fontSize: "24px",
        padding: "25px"
    },
    tableRow: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
    },
    tableHeader: {
        borderStyle: "solid",
        height: "auto",
        width: "100%",
        padding: 10,
        fontStyle: "bold",
        fontSize: 11,
        color: "#505050",
        flexGrow: 1,

    },
    tableCell: {
        borderStyle: "solid",
        height: "auto",
        width: "100%",
        margin: "auto",
        padding: 10,
        fontSize: 11,
        fontWeight: "normal",
        flexDirection: "row",
        flexGrow: 1,
    }
});


export const PageHeader = (props: ReactPDF.ViewProps) => {
    const {children, style, ...rest} = props;
    const styleSheet = style ? {...pdfStyles.pageHeader, ...style} : pdfStyles.pageHeader;
    return (
        <View {...rest} style={styleSheet}>
            {children}
        </View>
    )
}


export const PdfTable = (props: ReactPDF.ViewProps) => {
    const {children, style, ...rest} = props;
    const styleSheet = style ? {...pdfStyles.table, ...style} : pdfStyles.table;
    return (
        <View {...rest} style={styleSheet}>
            {children}
        </View>
    )
}

export const PdfTableRow = (props: ReactPDF.ViewProps) => {
    const {children, style, ...rest} = props;
    const styleSheet = style ? {...pdfStyles.tableRow, ...style} : pdfStyles.tableRow;
    return (
        <View {...rest} style={styleSheet}>
            {children}
        </View>
    )
}

export const PdfTableHeader = (props: ReactPDF.ViewProps) => {
    const {children, style, ...rest} = props;
    const styleSheet = style ? {...pdfStyles.tableHeader, ...style} : pdfStyles.tableHeader;
    return (
        <View {...rest} style={styleSheet}>
            {children}
        </View>
    )
}


export const PdfTableCell = (props: ReactPDF.ViewProps) => {
    const {children, style, ...rest} = props;
    const styleSheet = style ? {...pdfStyles.tableCell, ...style} : pdfStyles.tableCell;
    return (
        <View {...rest} style={styleSheet}>
            {children}
        </View>
    )
}
