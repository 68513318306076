import './responsiveProgress.scss';

import React from "react";
import {Progress, ProgressProps} from "reactstrap";
import {useMediaQuery} from "react-responsive";


export interface ResponsiveProgressProps extends ProgressProps {
    maxWidth?: string,
}

/**
 * Boostrap progress bar that will turn vertical on smaller screens
 */
export const ResponsiveProgress = (props: ResponsiveProgressProps) => {

    const {value, maxWidth = "768px", ...other} = props;

    // find out if we are on a smaller screen
    const isSmallScreen = useMediaQuery({maxWidth: maxWidth});

    return (
        <div className={"responsive-progress"}>
            {isSmallScreen ? (
                <div className={"progress-bg-vertical"}>
                    <div className={"progress-bar-vertical"} style={{
                        height: `${value}%`
                    }}/>
                </div>
            ) : (
                <div className={"progress-bg"}>
                    <Progress value={value} {...other}/>
                </div>
            )}
        </div>
    )

}