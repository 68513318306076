import gql from "graphql-tag";


export const equipmentTypeFieldsFragment = gql`
    fragment equipmentTypeFields on EquipmentType {
        id,
        name
    }

`
