import * as React from "react";
import {AsyncActionStatus} from "../../shared/abstractStore";
import {useAsyncCallback} from "react-use-async-callback";
import { EndUserService, EndUserAuthorizationRequest, EndUserAuthorizationResult } from "./EndUserService";

/**
 * Callback to Login using a password.
 */
export function useAuthorizeEndUserCallback(): [(model: EndUserAuthorizationRequest) => Promise<EndUserAuthorizationResult>, AsyncActionStatus] {
    const endUserService = new EndUserService();

    // Get the return url from the query string.
    const returnUrl = React.useMemo(() => {
        const params = new URLSearchParams(window.location.search);
        return params.get('returnUrl') ?? params.get('ReturnUrl') ?? '';
    }, []);

    const [action, { isExecuting, errors }] = useAsyncCallback(async (model: EndUserAuthorizationRequest) => {
        return await endUserService.authorizeEndUser(model, returnUrl);
    }, [endUserService]);

    return [action, { isExecuting, errors }];
}
