import * as React from 'react';
import {Redirect} from 'react-router';

/**
 * Default / home page.
 */
export const Home = () => {
    return (
        <Redirect to={{pathname: "/my-claim/", search:window.location.search}}/>
    );
};