import {AsyncLoadOptions, AsyncLoadResult} from "../../../shared/abstractStore";
import {useLazyQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {mainApiConfig} from "../../../configure/mainApiConfig";
import React from "react";
import {contractFieldsFragment} from "../models/Contract";
import {dataFormDataFieldsFragment} from "../models/DataFormData";
import {equipmentTypeFieldsFragment} from "../models/EquipmentType";
import {myClaimQuery} from "../generated/myClaimQuery";
import {stateFieldsFragment} from "../models/State";


export interface ClaimQueryOptions extends AsyncLoadOptions {
    id?: string,
}

export function useMyClaim(options: ClaimQueryOptions): AsyncLoadResult<myClaimQuery> {

    const [load, {loading, error, data, refetch}] = useLazyQuery(
        gql`
            query myClaimQuery($id: String!){
                item: claim(id: $id){
                    id,
                    number,
                    state {
                        ...stateFields
                    }
                    contract {
                        ...contractFields
                    }
                    equipmentType {
                        ...equipmentTypeFields
                    }
                    dataFormData {
                        ...dataFormDataFields
                    }
                }
            }
            ${contractFieldsFragment} ${dataFormDataFieldsFragment} ${equipmentTypeFieldsFragment}
            ${stateFieldsFragment}
        `, {
            variables: {id: options.id},
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted){
                    options.onCompleted(data);
                }
            }
        }
    )

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    const refresh = React.useCallback(
        async () => {!refetch ? await load() : await refetch();},
        [refetch, load]);

    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);

}
