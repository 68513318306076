import {AsyncLoadResult} from "../../../shared/abstractStore";
import {useLazyQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {claimFieldsFragment} from "../models/Claim";
import {claimSummaryQuery, claimSummaryQuery_item} from "../generated/claimSummaryQuery";
import {mainApiConfig} from "../../../configure/mainApiConfig";
import React from "react";
import {stateFieldsFragment} from "../models/State";
import {contractFieldsFragment} from "../models/Contract";
import {ClaimQueryOptions} from "./useMyClaim";
import {eventHistoryFields} from "../generated/eventHistoryFields";


interface ClaimSummaryOptions extends ClaimQueryOptions {
    number?: string | null
}

export interface ClaimSummaryResult {
    item?: claimSummaryQuery_item,
    finalEvent?: Partial<eventHistoryFields>
}


export function useClaimSummary(options: ClaimSummaryOptions)
    : AsyncLoadResult<ClaimSummaryResult> {
    // create a query

    const [result, setResult] = React.useState<ClaimSummaryResult|null>(null);
    const [load, {loading, error, data, refetch}] = useLazyQuery<claimSummaryQuery>(
        gql`
            query claimSummaryQuery($claimId: String, $number: String) {
                item : claim (id: $claimId, number: $number) {
                    ...claimFields
                    state {
                        ...stateFields
                    }
                    contract {
                        ...contractFields
                    }
                    equipmentReceived: claimEvent(name: "Claim state changed to Equipment Received.") {
                        id,
                        eventDate,
                        name
                    }
                }
            }
            ${claimFieldsFragment} ${stateFieldsFragment} ${contractFieldsFragment}
        `,
        {
            variables: {claimId: options.id},
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if(options.onCompleted){
                    options?.onCompleted(data);
                }
            }
        }
    );

    // TODO: maybe encapsulate below into a reusable hook?

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Parse data
    React.useEffect(() => {
        if (!result && data?.item) {

            const finalEvent = data.item.events
                .find(e => (e?.relatedState?.trackerNumber === 3 && e.relatedState.trackerStep === 100))

            setResult({
                item: data.item,
                finalEvent: finalEvent ?? undefined
            });

        }
    }, [data, result])


    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: result ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [result, refresh, loading, error]);
}