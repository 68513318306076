import gql from "graphql-tag";


export const stateFieldsFragment = gql`
    
    fragment stateFields on State {
        id
        active
        name
        systemName
        description
        nextState
        status,
        trackerStep,
        trackerNumber
    }
`;