import './styledModal.scss';

import React from "react";
import {Modal, ModalProps} from "reactstrap";


/**
 * Shared Bootsrap modal component with some common styling
 */
export function StyledModal(props: ModalProps){
    const {children, className, ...other} = props;

    return (
        <Modal className={`styled-modal ${className}`} {...other}>
            {children}
        </Modal>
    )
}