import React from "react";
import {useLazyQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {mainApiConfig} from "../../../configure/mainApiConfig";
import {AsyncLoadOptions} from "../../../shared/abstractStore";
import {claimIdQuery} from "../generated/claimIdQuery";


export interface ClaimValidationResult {
    isLoading: boolean,
    called: boolean,
    claimId?: string
    errors: any,
    load: () => Promise<void>
}

/**
 * Checks if the claim id is valid
 */
export function useValidateClaimId(claimId?: string | null, options?: AsyncLoadOptions): ClaimValidationResult {

    const [load, {loading, error, data, refetch, called}] = useLazyQuery<claimIdQuery>(
        gql`
            query claimIdQuery($claimId: String!) {
                item : claim (id: $claimId) {
                    id,
                    number
                }
            }
        `,
        {
            variables: {claimId: claimId},
            fetchPolicy: mainApiConfig.defaultFetchPolicy,
            pollInterval: options?.pollInterval,
            onCompleted: (data) => {
                if(options?.onCompleted){
                    options?.onCompleted(data);
                }
            }
        }
    )

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options?.lazy) {
            load();
        }
    }, [options, load, claimId])

    const refresh = React.useCallback(async () => {
        !refetch ? await load() : await refetch();
        }, [refetch, load]);

    return React.useMemo(() => ({
        isLoading: loading,
        claimId: data?.item?.id,
        errors: error,
        load: refresh,
        called,
    }), [called, data, refresh, loading, error]);
}