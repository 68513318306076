import './patternLock.scss';

import React from 'react';
import {Button, Container} from 'reactstrap';
import {Connector, ConnectorsProps, PatternLockNodeProps, PatternLockProps, Point} from "./types";
import {
    clientPointToCanvas,
    getAngle,
    getCollidedPointIndex,
    getConnectorPoint,
    getDistance,
    getIntersectedNodes,
    getNodePoints
} from './helpers';


export function PatternLockNode(props: PatternLockNodeProps & React.HTMLProps<HTMLDivElement>) {
    const { selected = false, index, ...divProps } = props;

    return (
        <div className={`pattern-lock-node-outer ${selected ? "selected" : ""}`} {...divProps}
        >
            <div className={`pattern-lock-node-inner`} />
        </div>
    )
}

/**
 * Component that is responsible for drawing connector lines
 * on the pattern lock
 */
export function Connectors(props: ConnectorsProps) {

    const {
        pattern,
        points,
        connectorThickness,
        nodeSize,
        canvasRef,
        initialMousePos = null,
    } = props;

    const [mouse, setMouse] = React.useState<Point | null>(null);
    React.useEffect(() => setMouse(initialMousePos), [initialMousePos])

    const { setMousePosition } = React.useMemo(() => ({
        setMousePosition: (point: Point): void => {
            const canvasPoint = clientPointToCanvas(canvasRef, point);
            if (canvasPoint) setMouse(canvasPoint);
        }
    }), [canvasRef])

    // subscribe to mouse and touch move events
    React.useEffect(() => {

        if (!initialMousePos) return;
        if (!canvasRef?.current) return;

        // copy ref inside the hook so we know it points to the same component
        // by the time cleanup is called
        const copiedRef = canvasRef.current;

        const onMouseMove = (e: MouseEvent) => {
            const point = clientPointToCanvas(canvasRef,{ x: e.clientX, y: e.clientY });
            if (!point) return;
            setMousePosition({ x: e.clientX, y: e.clientY });
        };

        const onTouchMove = (e: TouchEvent) => {
            setMousePosition({ x: e.touches[0].clientX, y: e.touches[0].clientY });
        }

        copiedRef.addEventListener("mousemove", onMouseMove);
        copiedRef.addEventListener("touchmove", onTouchMove);

        return () => {
            copiedRef?.removeEventListener("mousemove", onMouseMove);
            copiedRef?.removeEventListener("touchmove", onTouchMove);
        }
    }, [initialMousePos])


    // get connector points
    const connectors: Connector[] = [];
    for (let i = 0; i < pattern.length - 1; i++) {
        const currentPoint = points[pattern[i]];
        const nextPoint = points[pattern[i + 1]];
        connectors.push({
            from: getConnectorPoint(currentPoint, nodeSize, connectorThickness),
            to: getConnectorPoint(nextPoint, nodeSize, connectorThickness)
        })
    }

    // add connector being dragged
    if (mouse && pattern.length) {
        connectors.push({
            from: getConnectorPoint(points[pattern[pattern.length - 1]], nodeSize, connectorThickness),
            to: mouse
        })
    }

    return (
        <>
            {connectors.map(({ from, to }, i) => {
                return (

                    <div key={i}
                        className={`pattern-lock-connector`}

                        style={{
                            width: getDistance(from, to),
                            height: connectorThickness,
                            position: "absolute",
                            backgroundColor: "#4d4d4d",
                            top: from.y,
                            left: from.x,
                            transform: `rotate(${getAngle(from, to)}rad)`,
                            transformOrigin: "left 50%",
                            borderRadius: `${Math.round(connectorThickness / 2)}`
                        }}
                    />
                )
            })}
        </>
    )

}


export function PatternLockWidget(props: PatternLockProps) {

    const {
        onChange,
        onFinished,
        pixelSize,
        pattern: initialPattern = [],
        size: patternSize = 3,
    } = props;


    // pattern lock state
    const [pattern, setPattern] = React.useState<number[]>(initialPattern);
    const canvasRef = React.useRef<HTMLDivElement>(null);
    const [dragging, setDragging] = React.useState<boolean>(false);
    const nodeSize = 35; // TODO: make this not hardcoded!

    // coordinates and points
    const [startPoint, setStartPoint] = React.useState<Point | null>(null);
    const [points, setPoints] = React.useState<Point[]>([]);

    function checkCollision({ x, y }: Point) {

        const nodeOverIndex = getCollidedPointIndex({ x, y }, points, nodeSize);

        checkCollisionFromIndex(nodeOverIndex);
    }

    function checkCollisionFromIndex(nodeOverIndex: number) {
        // if there is no collision, or collision is with previous node, ignore
        if (nodeOverIndex === -1) return;
        if (pattern[pattern.length - 1] === nodeOverIndex) return;
        if (pattern.indexOf(nodeOverIndex) !== -1) return;

        // get all nodes intersected between last and current nodes, select only those that haven't been selected
        const intersectedPoints = getIntersectedNodes(pattern[pattern.length - 1], nodeOverIndex, patternSize);

        const newPattern = [...pattern, ...intersectedPoints.filter(p => pattern.indexOf(p) === -1), nodeOverIndex];

        if (onChange) onChange(newPattern);
        setPattern(newPattern);
    }

    // event that handles mouse down event
    function onMouseDown({ clientX, clientY }: React.MouseEvent) {

        // set dragging and set initial point
        setDragging(true);
        const point = clientPointToCanvas(canvasRef, { x: clientX, y: clientY });
        if (!point) return;

        setStartPoint(point);
        // checkCollision(point);
    }

    function onTouchStart(e: React.TouchEvent) {
        // set dragging and set initial point

        setDragging(true);
        const point = clientPointToCanvas(canvasRef,{ x: e.touches[0].clientX, y: e.touches[0].clientY });
        if (!point) return;
        setStartPoint(point);
        checkCollision(point);
    }

    /* == workaround to fix the issue where changed pattern state did not save in checkCollisions method == */
    // triggers when a mouse or touch starts on a node
    function mouseDownNode(index: number) {
        setDragging(true);
        setPattern([index]);
        if (onChange) onChange([index]);
    }

    // triggers when a pressed mouse or touch enters node
    function mouseEnterNode(index: number) {
        if (dragging) {
            checkCollisionFromIndex(index);
        }
    }
    /* ======== */

    React.useEffect(() => {
        if (!dragging) return;
        if (!canvasRef?.current) return;

        // copy ref so it's the same object when we're unsubscribing
        const copiedRef = canvasRef.current;

        const onMouseMove = ({ clientX, clientY }: MouseEvent) => {
            const point = clientPointToCanvas(canvasRef,{ x: clientX, y: clientY });
            if (point) checkCollision(point);
        };
        const onTouchMove = ({ touches }: TouchEvent) => {
            const point = clientPointToCanvas(canvasRef,{ x: touches[0].clientX, y: touches[0].clientY });
            if (point) checkCollision(point);
        };

        copiedRef.addEventListener("mousemove", onMouseMove);
        copiedRef.addEventListener("touchmove", onTouchMove);
        return () => {
            copiedRef.removeEventListener("mousemove", onMouseMove);
            copiedRef.removeEventListener("touchmove", onTouchMove);
        };
    });

    // get top left points from each node in the pattern lock
    React.useEffect(() => {
        if (!pixelSize) return;
        const points = getNodePoints(nodeSize, pixelSize, patternSize);
        setPoints(points)
    }, [pixelSize]);

    // subscribe to mouse and touch release event
    React.useEffect(() => {
        const onRealease = () => {
            setDragging(false);
            setStartPoint(null);
            onFinished(pattern);
        };

        window.addEventListener("mouseup", onRealease);
        window.addEventListener("touchend", onRealease);

        return () => {
            window.removeEventListener("mouseup", onRealease);
            window.removeEventListener("touchend", onRealease);
        }
    });

    return (

        <Container className={"pattern-lock-wrapper"} onTouchStart={e => onTouchStart(e)}>
            <div
                className={"pattern-lock"}
                ref={canvasRef}
                onMouseDown={onMouseDown}
                onTouchStart={onTouchStart}
                style={{
                    width: pixelSize,
                    height: pixelSize,
                }}
            >
                {Array.from({ length: patternSize ** 2 }).map((_, index) => {
                    return (
                        <div className={"pattern-lock-cell"} key={index}
                            style={
                                {
                                    display: "flex",
                                    flex: `1 0 ${100 / patternSize}%`,
                                    alignItems: "center",
                                    justifyContent: "center",
                                    zIndex: 500,
                                }
                            }>
                            <PatternLockNode
                                selected={pattern.includes(index)}
                                index={index}
                                onMouseEnter={e => mouseEnterNode(index)}
                                onMouseDown={e => mouseDownNode(index)}
                                //onTouchMove={e => mouseEnterNode(index)}
                                //onTouchStart={e => mouseDownNode(index)}


                            />
                        </div>
                    )
                })}
                {points.length && canvasRef && (
                    <Connectors
                        connectorThickness={4}
                        initialMousePos={startPoint}
                        nodeSize={nodeSize}
                        pattern={pattern}
                        points={points}
                        canvasRef={canvasRef}
                    />
                )}
            </div>
            <Button type={"button"}
                className={"clear-pattern"}
                color={"secondary"}
                onClick={() => {
                    setPattern([]);
                    if (canvasRef?.current) canvasRef.current.focus();
                }}
            >
                {"Clear"}
            </Button>
        </Container>
    )
}



