import {useLazyQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {mainApiConfig} from "../../../configure/mainApiConfig";
import {AsyncLoadResult} from "../../../shared/abstractStore";
import React from "react";
import {ClaimQueryOptions} from "./useMyClaim";
import {messageFieldsFragment} from "../models/Message";
import {claimMessagesQuery} from "../generated/claimMessagesQuery";


export function useClaimMessages(options: ClaimQueryOptions): AsyncLoadResult<claimMessagesQuery> {
    // query data
    const [load, {data, loading, error, refetch}] = useLazyQuery(
        gql`
            query claimMessagesQuery ($id: String!) {
                claim (id: $id) {
                    id,
                    messages {
                        ...messageFields
                    }
                    
                }
            }
            ${messageFieldsFragment}
        `, {
            variables: {
                id: options.id,
            },

            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted){
                    options.onCompleted(data);
                }
            }
        }
    )

    // run the query unless lazy loading is specified
    React.useEffect(() => {
        if (!options.lazy){
            load();
        }
    }, [options.lazy, load])

    // return the results
    const refresh = React.useCallback(async () => {
            !refetch? await load() : await refetch();
    }, [refetch, load])

    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error])
}