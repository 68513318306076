import {ApiFetch} from "apifetch-json";

export interface EndUserAuthorizationRequest {
    reference: string,
    postcode: string,
}

export interface EndUserAuthorizationResult {
    succeeded: boolean,
    claimId?: string,
    returnUrl: string
}

export interface ClaimPhotosResult  { [key: string]: string }

export interface SendMessageRequest {
    claimId: string,
    subject: string,
    message: string,
}

export class EndUserService {
    api: ApiFetch;

    constructor(api?: ApiFetch) {
        this.api = api ?? new ApiFetch();
    }

    authorizeEndUser = async (request: EndUserAuthorizationRequest, returnUrl?: string) => {
        const result = await this.api.post<EndUserAuthorizationResult>(
            `/api/enduser/login?returnUrl=${encodeURIComponent(returnUrl ?? '')}`,
            request,
        );
        return result;
    }

    getClaimPhotos = async (id: string) => {
        const result = await this.api.get<ClaimPhotosResult>(`/api/media/inspectionPhotos?claimId=${encodeURIComponent(id)}`);
        return result;
    }

    sendMessage = async (request: SendMessageRequest) => {
        const result = await this.api.post("api/enduser/sendMessage", request);
        return result;
    }

}
