import './claimSummary.scss';

import * as React from 'react';
import {Col, Container, Row} from "reactstrap";
import {ClaimCurrentStateSummary} from "../shared/ClaimCurrentStateSummary";
import {ClaimNextStageSummary} from "../shared/ClaimNextStageSummary";
import {MyReference} from "../shared/MyReference";
import {useCurrentClaimId} from "../../api/main/claims/useCurrentClaimId";
import {useClaimSummary} from "../../api/main/claims/useClaimSummary";
import {ClaimProgressStatus} from "../shared/ClaimProgressStatus";
import {useValidateClaimId} from "../../api/main/claims/useValidateClaimId";
import {useClaimReadyByDate} from "../../api/main/claims/useClaimReadyByDate";


export const ClaimSummary = () => {

    const [readyByDate, setReadyByDate] = React.useState<string>("");

    const claimId = useCurrentClaimId();
    const {called, claimId: validatedId} = useValidateClaimId(claimId);

    const {data: {item, finalEvent}, isLoading} = useClaimSummary({
        id: claimId
    });

    const readyDate = useClaimReadyByDate({
        claimId: claimId,
        collectionDate: item?.nextCollectionAttemptDate,
        dateReceived: item?.equipmentReceived?.eventDate,
        contractSlaDays: item?.contract?.tatSlaDays ?? 0,
        finalEvent,
    })

    React.useEffect(() => {
        if (readyDate){
            setReadyByDate(readyDate?.format("DD/MM/YYYY") ?? "")
        }
    }, [readyDate])

    if (called && (!validatedId || validatedId !== claimId)) {
        return <></>
    }

    return (
        <Container className={`summary ${isLoading ? " loading" : ""}`} fluid={true}>
            <Row>
                <Col sm={12}>
                    <ClaimProgressStatus status={item?.state?.status} isLoading={isLoading && !item?.state?.status}/>
                </Col>
            </Row>
            <Row>
                <Col lg={4} sm={12}>
                    <ClaimCurrentStateSummary claimState={item?.state?.name} isLoading={isLoading}/>
                </Col>
                <Col lg={5} sm={12}>
                    <ClaimNextStageSummary nextStage={item?.state?.nextState} readyByDate={readyByDate} isLoading={isLoading && readyByDate !== ""}/>
                </Col>
                <Col lg={3} sm={12}>
                    <MyReference reference={item?.number} isLoading={isLoading && !item?.number}/>
                </Col>
            </Row>
        </Container>
    )
}
