import * as React from 'react';
import {Route, Switch} from 'react-router';
import {RouteEntry} from '../../shared/routes';
import {AuthorizeRoute} from '../../shared/authorizeRoute';

export interface AppRoutesProps {
    routes: Array<RouteEntry>,
}

export const AppRoutes = (props: AppRoutesProps) => {
    const { routes } = props;

    return (
        <Switch>
            {
                routes.map((item, index) => {
                    if (item.authorize || item.requireRole) {
                        return (<AuthorizeRoute key={index} {...item} />);
                    }

                    // Otherwise a simple Route will do.
                    return (<Route key={index} {...item} />);
                })
            }
        </Switch>
    );
};
