import gql from "graphql-tag";
import {partFieldsFragment} from "./Part";


export const portalNotificationFieldsFragment = gql`
    fragment portalNotificationFields on PortalNotification {
        id,
        name,
        linkName,
        linkPath

    }
    `