import {useMutation} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {useAsyncCallback} from "react-use-async-callback";
import {dataFormDataFieldsFragment} from "../models/DataFormData";
import {SetPasscodeInput} from "../generated/globalTypes";
import {AsyncActionStatus} from "../../../shared/abstractStore";
import {submitPasscode, submitPasscodeVariables} from "../generated/submitPasscode";


/**
 * Get a callback to update delivery address for the claim
 */
export function useSubmitPasscode(): [(model: SetPasscodeInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, {error}] = useMutation<submitPasscode, submitPasscodeVariables>(
        gql`
            mutation submitPasscode($model: SetPasscodeInput!) {
                setPasscode(model: $model) {
                    ...dataFormDataFields
                }
            }
            ${dataFormDataFieldsFragment}
        `);

        // Wrap the mutation in a standard callback.
        const [action, {isExecuting, errors: actionErrors}] = useAsyncCallback(
            async (model: SetPasscodeInput) => {
                if (model.claimId === "") throw new Error("Could not get claim id!")
                const result = await mutationAction({variables: {model: model}});
                if (result.errors && result.errors.length){
                    throw result.errors;
                }
            }, [mutationAction]
        )

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}