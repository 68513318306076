import './myDelivery.scss';

import React from "react";
import {TrackedClaimContainer} from "../shared/TrackedClaimContainer";
import {PageSection} from "../../shared/pageSection/PageSection";
import {useCurrentClaimId} from "../../api/main/claims/useCurrentClaimId";
import {Alert, Button, Media} from "reactstrap";
import {NameValueBox} from "../../shared/nameValueView/NameValueBox";
import {useClaimDeliveryDetails} from "../../api/main/claims/useClaimDeliveryDetails";
import {LoadingComponent} from "../shared/LoadingComponent";
import {AlertOnErrors} from "../../shared/alertOnErrors";
import {ConditionalFragment} from "../../shared/conditionalFragment";
import moment from "moment";


export function MyDelivery() {

    const claimId = useCurrentClaimId();
    const { data: { item }, isLoading, errors } = useClaimDeliveryDetails({ id: claimId });
    const [isDeliveryArranged, setIsDeliveryArranged] = React.useState<boolean>(false);

    const [collectionCourierImage, setCollectionCourierImage] = React.useState<string|null>(null);
    const [deliveryCourierImage, setDeliveryCourierImage] = React.useState<string|null>(null);

    const [deliveryRef, setDeliveryRef] = React.useState<string>("");
    const [collectionRef, setCollectionRef] = React.useState<string>("");

    // tracking
    // const { data: trackingData, isLoading: loadingTracking } = useClaimDeliveryTracking(claimId);

    React.useEffect(() => {
        setIsDeliveryArranged(!!item?.deliveryCourier && item.deliveryCourier !== "");
    }, [setIsDeliveryArranged, item])

    // work out courier tracking references
    React.useEffect(() => {
        setDeliveryRef(
            (item?.dpddeliveryNumber && item?.dpddeliveryNumber !== "")
                ? item.dpddeliveryNumber
                : item?.deliveryTrackingDetails ?? ""
        );
        setCollectionRef(
            (item?.dpdcollectionNumber && item?.dpdcollectionNumber !== "")
                ? item.dpdcollectionNumber
                : item?.collectionTrackingDetails ?? ""
        );
    }, [item])


    React.useEffect(() => {
        if (item?.deliveryCourier) {
            if (item.deliveryCourier.toLowerCase().includes("royal mail")) setDeliveryCourierImage("royalmail.png");
            if (item.deliveryCourier.toLowerCase().includes("dpd")) setDeliveryCourierImage("dpd.png");
        }

    }, [item, setDeliveryCourierImage])

    React.useEffect(() => {
        if (item?.collectionCourier) {
            if (item.collectionCourier.toLowerCase().includes("royal mail")) setCollectionCourierImage("royalmail.png");
            if (item.collectionCourier.toLowerCase().includes("dpd")) setCollectionCourierImage("dpd.png");
        }
    }, [item, setCollectionCourierImage])


    return (
        <TrackedClaimContainer className={"my-delivery"}>
            <PageSection>
                <AlertOnErrors errors={[errors]} />
                <div className={"delivery-header"}>
                    <LoadingComponent isLoading={isLoading}>
                        <div className={"title"}>
                            <h1>{"Track my delivery"}</h1>
                        </div>
                    </LoadingComponent>
                    {isDeliveryArranged && item?.deliveryCourier && (
                        <div className={"courier-link-wrapper"}>
                            <Button color={"primary"} className={"courier-link"} onClick={e => {
                                if (item?.deliveryCourier && item.deliveryCourier.toLowerCase().includes("royal mail")) {
                                    window.open(`https://www3.royalmail.com/track-your-item#/tracking-results/${deliveryRef}`);
                                }
                                if (item?.deliveryCourier && item.deliveryCourier.toLowerCase().includes("dpd")) {
                                    window.open(`https://www.dpd.co.uk/apps/tracking/?reference=${deliveryRef}`);
                                }
                            }}>
                                {`View on ${item.deliveryCourier}`}
                            </Button>
                        </div>
                    )}
                </div>
                <div className={"delivery-summary"}>
                    <ConditionalFragment showIf={!isDeliveryArranged}>
                        <Alert color={"secondary"}>{"Your delivery has not been arranged yet."}</Alert>
                    </ConditionalFragment>
                    {item && deliveryRef && (
                        <>
                            <NameValueBox name={"Courier"} size={"md"} displayEmpty={false}>
                                {deliveryCourierImage ? (
                                    <div className={"image-wrapper"}>
                                        <Media className={"courier-image"} src={process.env.PUBLIC_URL + `/img/logos/${deliveryCourierImage}`} alt={item.deliveryCourier ?? ""} />
                                    </div>
                                ) : (
                                    item.deliveryCourier ?? ""
                                )}
                            </NameValueBox>
                            <NameValueBox name={"Tracking Number"} value={deliveryRef} size={"md"} displayEmpty={false} />
                            <NameValueBox name={"Date Dispatched"} value={
                                item.dispatchEvent?.eventDate ? moment(item.dispatchEvent?.eventDate).format("DD/MM/YYYY")
                                    : ""
                            } size={"md"} displayEmpty={false} />
                        </>
                    )}
                </div>
            </PageSection>
            <PageSection>
                <AlertOnErrors errors={[errors]} />
                <div className={"delivery-header"}>
                    <LoadingComponent isLoading={isLoading}>
                        <div className={"title"}>
                            <h1>{"Track my collection"}</h1>
                        </div>
                    </LoadingComponent>
                    {item?.collectionCourier && collectionRef !== '' && (
                        <div className={"courier-link-wrapper"}>
                            <Button color={"primary"} className={"courier-link"} onClick={e => {
                                if (item?.collectionCourier && item.collectionCourier.toLowerCase().includes("royal mail")) {
                                    window.open(`https://www3.royalmail.com/track-your-item#/tracking-results/${collectionRef}`);
                                }
                                if (item?.collectionCourier && item.collectionCourier.toLowerCase().includes("dpd")) {
                                    window.open(`https://www.dpd.co.uk/apps/tracking/?reference=${collectionRef}`);
                                }
                            }}>
                                {`View on ${item.collectionCourier}`}
                            </Button>
                        </div>
                    )}
                </div>
                <div className={"delivery-summary"}>
                    <ConditionalFragment showIf={!item?.collectionCourier || item.collectionCourier === ""}>
                        <Alert color={"secondary"}>{"Your collection has not been arranged yet."}</Alert>
                    </ConditionalFragment>
                    {item && (
                        <>
                            <NameValueBox name={"Courier"} size={"md"} displayEmpty={false}>
                                {collectionCourierImage ? (
                                    <div className={"image-wrapper"}>
                                        <Media
                                            className={"courier-image"}
                                            src={process.env.PUBLIC_URL + `/img/logos/${collectionCourierImage}`}
                                            alt={item.collectionCourier ?? ""} />
                                    </div>
                                ) : (
                                    item.collectionCourier ?? ""
                                )}
                            </NameValueBox>
                            <NameValueBox name={"Tracking Number"} value={collectionRef} size={"md"} displayEmpty={false} />
                            <NameValueBox name={"Collection Date"} value={
                                item.dispatchEvent?.eventDate ? moment(item.nextCollectionAttemptDate).format("DD/MM/YYYY")
                                    : ""
                            } size={"md"} displayEmpty={false} />
                        </>
                    )}
                </div>
            </PageSection>
            {/*  {trackingData?.trackerDetails && trackingData.trackerDetails.length !== 0 && (
                <PageSection>
                    <LoadingComponent isLoading={loadingTracking}>
                        <h1>Status</h1>
                    </LoadingComponent>
                    <Table borderless className={"status-table"}>
                        <thead>
                            <tr className={"header-row"}>
                                <td>Date</td>
                                <td>Location</td>
                                <td>Description</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                trackingData.trackerDetails.map((d, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{d.dateTime ? moment(d.dateTime).format("DD MMM YYYY HH:mm") : ""}</td>
                                            <td>{d.location}</td>
                                            <td>{d.message}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </PageSection>
            )}*/}
        </TrackedClaimContainer>
    )

}
