import {RouteEntry} from "../shared/routes";
import {apiAuthorizationRoutes} from "../components/api-authorization/routes";
import {accountRoutes} from "../components/account/routes";
import {NotFound} from "../components/shared/NotFound";
import {homeRoutes} from "../components/home/routes";
import {myClaimRoutes} from "../components/myClaim/routes";
import {myDeliveryRoutes} from "../components/myDelivery/routes";
import {myDetailsRoutes} from "../components/myDetails/routes";
import {myMessagesRoutes} from "../components/messages/routes";
import {historyRoutes} from "../components/history/routes";
import {photosRoutes} from "../components/photos/routes";
import {quoteRoutes} from "../components/quote/routes";
import {reportRoutes} from "../components/report/routes";
import {trackRoutes} from "../components/track/routes";
import { excessPaymentRoutes } from "../components/excessPayment/routes";


export const appRoutes: Array<RouteEntry> = [
    ...apiAuthorizationRoutes,
    ...accountRoutes,
    ...excessPaymentRoutes,
    ...homeRoutes,
    ...historyRoutes,
    ...myMessagesRoutes,
    ...myClaimRoutes,
    ...myDetailsRoutes,
    ...photosRoutes,
    ...quoteRoutes,
    ...reportRoutes,
    ...myDeliveryRoutes,
    ...trackRoutes,

    { path: undefined, component: NotFound }
];
