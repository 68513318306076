import {ClaimPhotosResult, EndUserService} from "../../account/EndUserService";
import React from "react";


export function useGetInspectionPhotos(id?: string): {loading: boolean, photos: ClaimPhotosResult, errors: any[]} {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState(null);
    const [photos, setPhotos] = React.useState<ClaimPhotosResult|undefined>();

    async function loadData(){
        if (!id) return;

        const claimService = new EndUserService();
        try {
            setIsLoading(true);
            const result = await claimService.getClaimPhotos(id);
            setPhotos(result);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }

    React.useEffect(() => {
        loadData();
    }, [id])

    return React.useMemo(() => ({
        loading: isLoading,
        photos: photos ?? {} as any,
        errors: [error]
    }), [photos, isLoading, error])
}