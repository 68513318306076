import React from "react";
import moment from "moment";
import momentBusinessDays from "moment-business-days";
import {eventHistoryFields} from "../generated/eventHistoryFields";


export interface ClaimReadyByDateParams {
    claimId?: string;
    dateReceived?: moment.Moment;
    collectionDate?: moment.Moment;
    finalEvent?: Partial<eventHistoryFields>;
    contractSlaDays: number;
}

/**
 * Returns the ready by date for a claim, which is
 * either the SLA date worked out from received date
 * or SLA date approximated from estimated arrival date
 */
export function useClaimReadyByDate(params: ClaimReadyByDateParams): moment.Moment | null {

    const {
        claimId,
        dateReceived,
        collectionDate,
        contractSlaDays,
        finalEvent,
    } = params;

    return React.useMemo(() => {

        if (!claimId) return null;
        // grab the date of final event if its at 100%
        if (finalEvent?.eventDate && finalEvent?.relatedState?.trackerStep === 100) {
            return moment(finalEvent.eventDate);
        }
        // at this point we have to work out sla date. First, from received date
        if (dateReceived) {
            return moment(moment(dateReceived).add(contractSlaDays, "days"));
        }
        // if device hasn't been delivered, work out approximate arrival date and sla
        if (collectionDate && collectionDate > moment("2000-01-01")) {
            return moment(momentBusinessDays(collectionDate).businessAdd(2, "days"));
        }
        // asked to return contract SLA + 3 days instead of null
        return moment(moment.now()).add(contractSlaDays, "days").add(3, "days");

    }, [claimId, collectionDate, dateReceived, contractSlaDays, finalEvent])


}
