import React, { useMemo } from "react";
import { useParams } from "react-router";
import { ExcessPayment } from "./ExcessPayment";
import { useCurrentClaimId } from "../../api/main/claims/useCurrentClaimId";
import { TrackedClaimContainer } from "../shared/TrackedClaimContainer";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { stripeConfig } from "../../configure/stripeConfig";

/**
 * Redirected here after payment excess is complete.
 */
export const ExcessPaymentComplete = () => {
    const { claimId } = useParams();
    
    const {
        redirect_status,
    } = useMemo(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);

        return {
            payment_intent: urlSearchParams.get('payment_intent'),
            payment_intent_client_secret: urlSearchParams.get('payment_intent_client_secret'),
            redirect_status: urlSearchParams.get('redirect_status'),
        };
    }, []);

    return (
        <ExcessPayment
            claimId={claimId}
            paymentStatus={redirect_status ?? undefined}
            />
    );
};

// Make sure to call `loadStripe` outside of a component�s render to avoid recreating the `Stripe` object on every render.
const stripePromise = loadStripe(stripeConfig.publishableKey);

export function StripeExcessCompleteContainer() {

    const [clientSecret, setClientSecret] = React.useState("");
    const claimId = useCurrentClaimId();

    React.useEffect(() => {
        if (!claimId) {
            return;
        }

        // Create PaymentIntent as soon as the page loads
        fetch("/api/create-payment-intent/Create", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ item: { id: claimId } }),
        })
            .then((res) => res.json())
            .then((info) => setClientSecret(info.clientSecret));
    }, [claimId]);

    const options = {
        clientSecret
    };

    return (
        <TrackedClaimContainer className={"excess-payment"}>
            {clientSecret && (
                <Elements stripe={stripePromise} options={options}>
                    <ExcessPaymentComplete />
                </Elements>
            )}
        </TrackedClaimContainer>
    );

}