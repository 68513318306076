import {useMutation} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {useAsyncCallback} from "react-use-async-callback";
import {dataFormDataFieldsFragment} from "../models/DataFormData";
import {AsyncActionStatus} from "../../../shared/abstractStore";
import {ConfirmIcloudLockInput} from "../generated/globalTypes";
import {submitIcloudStatus, submitIcloudStatusVariables} from "../generated/submitIcloudStatus";


/**
 * Get a callback to update delivery address for the claim
 */
export function useSubmitIcloudStatus(): [(model: ConfirmIcloudLockInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, {error}] = useMutation<submitIcloudStatus, submitIcloudStatusVariables>(
        gql`
            mutation submitIcloudStatus($model: ConfirmIcloudLockInput!) {
                confirmIcloudLock(model: $model) {
                    ...dataFormDataFields
                }
            }
            ${dataFormDataFieldsFragment}
        `);

    // Wrap the mutation in a standard callback.
    const [action, {isExecuting, errors: actionErrors}] = useAsyncCallback(
        async (model: any) => {
            if (model.claimId === "") throw new Error("Could not get claim id!")
            const result = await mutationAction({variables: {model: model}});
            if (result.errors && result.errors.length){
                throw result.errors;
            }
        }, [mutationAction]
    )

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}