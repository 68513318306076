import './nameValueBox.scss';
import React from "react";

export interface NameValueProps {
    name?: string,
    value?: string
    displayEmpty?: boolean
    placeholder?: boolean
    size?: 'sm' | 'md' | 'lg',
    inline?: boolean,
    children?: React.ReactNode,
}


/**
 * Component to display a name-value pair or a fancy placeholder
 */
export const NameValueBox = (props: NameValueProps) => {
    const {
        name,
        value,
        children,
        size = 'md',
        placeholder = false,
        displayEmpty = true,
        inline = false,
    } = props

    const display = placeholder || (value && value.trim() !== '') || children || displayEmpty

    return (
        <>
            {display && (
                <div className={`name-value-box ${inline ? "inline" : size}  ${placeholder ? "placeholder" : ""}`}>
                    <div className={"name"}>{placeholder ? "" : name}</div>
                    {children ? (
                        children
                    ) : (
                        <div className={"value"}>{placeholder ? "" : value?.split("\n").map((text, index) => {
                            return <div key={index}>{text}</div>
                        })}</div>
                    ) }
                </div>
            )}
        </>
    )
}