import {Library, library} from '@fortawesome/fontawesome-svg-core';
import {
    faBusinessTime,
    faCalendarWeek,
    faCaretDown,
    faCaretLeft,
    faCaretRight,
    faCaretUp,
    faCircle,
    faCogs,
    faComment,
    faDownload,
    faEdit,
    faEllipsisV,
    faExclamation,
    faExclamationCircle,
    faEye,
    faEyeSlash,
    faLock,
    faMinus,
    faPencilAlt,
    faPlus,
    faProjectDiagram,
    faSave,
    faSearch,
    faSpinner,
    faStop,
    faStopwatch,
    faTimes,
    faTimesCircle,
    faTrash,
    faUndo,
    faUser,
    faUserClock,
    faUsers,
    faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import {faFrown as farFrown, faFrownOpen as farFrownOpen, faHourglass} from '@fortawesome/free-regular-svg-icons';

// Import the fontawsome icons we actually want to use.
export const initializeIcons = () => {
    configureIcons(library);
};

export const configureIcons = (library: Library) => {
    library.add(
        // solid
        faBusinessTime,
        faCalendarWeek,
        faCaretUp,
        faCaretDown,
        faEye,
        faEyeSlash,
        faEllipsisV,
        faExclamation,
        faCaretLeft,
        faCaretRight,
        faDownload,
        faCogs,
        faEdit,
        faExclamationCircle,
        faLock,
        faMinus,
        faPlus,
        faProjectDiagram,
        faPencilAlt,
        faSave,
        faSearch,
        faSpinner,
        faStop,
        faStopwatch,
        faTimes,
        faTimesCircle,
        faTrash,
        faUndo,
        faUser,
        faUserClock,
        faUsers,
        faComment,
        faCircle,
        faCheckCircle,

        // regular
        farFrown,
        farFrownOpen,
        faHourglass,
    );
};
