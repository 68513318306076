import './notification.scss';

import React from "react";
import {useClaimNotification} from "../../api/main/claims/useClaimNotification";
import {useLocation} from "react-router";
import {Link} from "react-router-dom";
import {Container} from "reactstrap";
import {AlertOnErrors} from "../../shared/alertOnErrors";


export const Notification = (props: {claimId?: string, refreshRequired?: boolean}) => {
    const [refreshRequired, setRefreshRequired] = React.useState<boolean>(props.refreshRequired ?? false);

    const location = useLocation();

    const {data: {item}, isLoading, errors, refresh} = useClaimNotification({id: props.claimId});

    React.useEffect(() => {
        if (refreshRequired) {
            setRefreshRequired(false);
            refresh();
        }
    }, [refresh, refreshRequired])

    if (isLoading && !item){
        return (<></>);
    }

    // If we are an excess payment notification, then link to that tab instead of a modal.
    //
    // They previously had an issue where the /excess-payment was going on the end of the URL in the same way other notification links do. 
    // My guess is this is caused from linkpath not loading yet so if linkpath is empty we will use "".
    const linkPath = item?.notification?.linkPath == "/excess-payment" ? `/excess-payment/${props.claimId}` : item?.notification?.linkPath ? location.pathname + (item?.notification?.linkPath ?? "") : "";


    return (
        item?.notification ? (
            <Container fluid>
                <AlertOnErrors errors={errors}/>
                <div className={"notification"}>
                    <div className={"notification-message"}>{item.notification?.name}</div>
                    <div className={"link"}>
                        <Link to={linkPath}>{item.notification.linkName}</Link>
                    </div>
                </div>

            </Container>
        ) : (
            <></>
        )
    )
}
