import gql from "graphql-tag";
import {partFieldsFragment} from "./Part";


export const quoteDetailFieldsFragment = gql`
    fragment quoteDetailFields on QuoteDetail {
        id,
        partId,
        quoteId,
        description,
        total,
        vat,

    }
    `