import "./gdprConsentNotePart.scss";
import * as React from 'react';
import {FormGroup, Label} from "reactstrap";
import {ValidatedInput} from "pojo-validator-reactstrap";

export interface GDPRConsentFormPartProps {
    validationErrors: string | string[];
    onChange?: (checked: boolean) => void,
    checked?: boolean,
    onBlur?: (e: React.FocusEvent) => void
}

export const GDPRConsentFormPart = (props: GDPRConsentFormPartProps) => {
    const {checked = false, onChange, validationErrors, onBlur} = props;

    return (
        <FormGroup className={"gdpr-notice"}>
            <Label htmlFor={"gpdrConsent"}>{"GPDR Consent"}</Label>
            <FormGroup className={"gdpr-consent-checkbox"}>
                <Label check>
                    <ValidatedInput type={"checkbox"}
                                    name={"gdprConsent"}
                                    checked={checked}
                                    onChange={(e) => onChange && onChange(e.currentTarget.checked)}
                                    validationErrors={validationErrors}
                                    onBlur={onBlur}
                    />
                    <p>
                        {"  We will only ever use your personal information in line with our"}
                        <a href={"http://www.greenbridge.co.uk/privacy-policy/"}  target={"_blank"} rel={"noreferrer noopener"}>
                            {" Privacy Notice"}
                        </a>
                    </p>
                </Label>
            </FormGroup>
        </FormGroup>
    );
};
