import './header.scss';

import * as React from 'react';
import {Col, Container, Nav, Row} from 'reactstrap';
import {Link, Route} from 'react-router-dom';
import {Navigation} from './Navigation';
import {AppNavbarBrand} from '../shared/AppNavbarBrand';
import {ChatButton} from "../shared/ChatButton";
import {ClaimSummary} from "./ClaimSummary";


export function Header(props: {}) {

    return (
        <Container fluid className={"header-container"}>
            <div className={"app-header"}>
                <Container className={"header-contents"}>
                    <Row>
                        <Col md={6} sm={12}>
                            <Link to="/">
                                <AppNavbarBrand/>
                            </Link>
                        </Col>
                    </Row>
                    {/*Display claim specific fields if we have a claim*/}
                    <Route path={"/:page/:id"}>
                        <Row>
                            <Col md={12}>
                                <ClaimSummary/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Nav className="menu-nav flex-grow-1">
                                    <Navigation/>
                                </Nav>
                            </Col>
                        </Row>
                    </Route>
                </Container>
            </div>
        </Container>
    )
}

