import gql from "graphql-tag";
import {stateFieldsFragment} from "./State";


export const eventHistoryFieldsFragement = gql`
    fragment eventHistoryFields on EventHistory {
        id,
        eventDate,
        name,
        sharedKey,
        engineer,
        relatedState {
            ...stateFields
        }
    }
    ${stateFieldsFragment}
`
