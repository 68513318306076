import './patternEntry.scss';

import React from "react";
import {Alert, FormGroup, Label, Spinner} from "reactstrap";
import {ButtonAsync} from "reactstrap-buttonasync";
import {PatternLockWidget} from "../patternLock/PatternLock";
import {useMediaQuery} from 'react-responsive';
import {ConditionalFragment} from "../../shared/conditionalFragment";

export interface PinEntryProps {
    onChangeCallback: (passcode: string) => void
    isLoading: boolean,
    active: boolean,
}


/**
 * Form group containing input specialised for pattern lock entry
 */
export function PatternEntry(props: PinEntryProps) {
    const { onChangeCallback, active, isLoading = false, } = props;
    const [valid, setValid] = React.useState<boolean>(true);

    const isSmallScreen = useMediaQuery({maxWidth: "768px"});

    function onFinished(pattern: number[]) {
        // we have to tell pattern lock that we activated it when we click on its tab
        // this fixes a bug where it was tracking mouse events even though it was not visible
        if (!active) return;
        if (pattern.length > 0 && pattern.length < 4) {
            setValid(false);
        } else {
            setValid(true);
            onChangeCallback(pattern.join(","))
        }
    }

    return (
        <FormGroup className={"pattern-entry"}>
            <div className={"password-label-area"}>
                <Label>{"Please draw pattern:"}</Label>
            </div>
            <ConditionalFragment showIf={!valid}>
                <Alert color={"danger"}>{"Pattern must consist of at least 4 nodes!"}</Alert>
            </ConditionalFragment>
            <div className={"password-submit-area"}>
                <PatternLockWidget size={3} onFinished={onFinished} pixelSize={isSmallScreen ? 305 : 405}/>
            </div>
            <ButtonAsync className={"submit-button"}
                type={"submit"}
                color={"primary"}
                isExecuting={isLoading}
                executingChildren={<><Spinner size={"sm"} /></>}>
                {"Submit"}
            </ButtonAsync>
        </FormGroup>
    )
}