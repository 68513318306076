import "./myDetails.scss";

import React from "react";
import {TrackedClaimContainer} from "../shared/TrackedClaimContainer";
import {PageSection} from "../../shared/pageSection/PageSection";
import {useClaimEndUserDetails} from "../../api/main/claims/useClaimEndUserDetails";
import {useCurrentClaimId} from "../../api/main/claims/useCurrentClaimId";
import {NameValueBox} from "../../shared/nameValueView/NameValueBox";
import {Button, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalHeader, Spinner} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AlertOnErrors} from "../../shared/alertOnErrors";
import {useChanges} from "../../shared/useChanges";
import {useUpdateDeliveryAddress} from "../../api/main/claims/useUpdateDeliveryAddress";
import {FormButtons} from "../shared/FormButtons";
import {ButtonAsync} from "reactstrap-buttonasync";
import {StyledModal} from "../shared/StyledModal";
import {LoadingComponent} from "../shared/LoadingComponent";
import {useFormattedAddressCallback} from "../../shared/useFormattedAddressCallback";
import {GDPRConsentFormPart} from "../shared/GDPRConsentFormPart";
import {useValidatorCallback} from "pojo-validator-react";
import moment from "moment";


interface DeliveryAddressInput {
    line1: string,
    line2: string,
    line3: string,
    city: string,
    postcode: string,
    gdprConsent?: boolean,
}

export function MyDetails(){
    const [modal, setModal] = React.useState<boolean>(false);
    const [addressChangeAllowed, setAddressChangeAllowed] = React.useState<boolean>(true);

    // get claim reference from url
    const claimId = useCurrentClaimId();
    const {data: {item}, isLoading} = useClaimEndUserDetails({id: claimId})

    // get the callback for updating registered address
    const [updateAddressCallback, {errors: updateErrors, isExecuting}] = useUpdateDeliveryAddress();
    const {model, change} = useChanges<DeliveryAddressInput>({line1: "", line2: "", line3: "", city: "", postcode: "", gdprConsent: false})
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            gdprConsent: () => !model.gdprConsent ? 'Please tick this box if you consent to our Privacy Notice' : ''
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);
    const formatAddress = useFormattedAddressCallback();

    const toggleModal = () => setModal(!modal);
    const updateRegisteredAddress = React.useCallback(async (): Promise<void> => {
        if (!validate()) return;

        if (claimId){
            const deliveryAddress = `${model.line1}\r\n${model.line2}\r\n${model.line3}\r\n${model.city}\r\n${model.postcode}`;
            await updateAddressCallback({id: claimId, deliveryAddress: deliveryAddress});
            setModal(false);
        }
    },[validate, claimId, model, updateAddressCallback]);

    // check that we are allowed to change address
    React.useEffect(() => {
        if (item?.state) {
            // address change is now allowed when claim is close to delivery
            if (item.state.status === "Completed") {
                setAddressChangeAllowed(false);
            } else {
                // check that repair finished less than 12 hours ago
                const dateRepairFinished = item.repairFinishedEvent[0]?.eventDate;
                if (dateRepairFinished) {
                    const lastChanceDate = moment(dateRepairFinished).add(12, "h");
                    setAddressChangeAllowed(moment().diff(lastChanceDate, "hour") <= 12);
                }
            }
        }
    }, [item])

    return (
        <TrackedClaimContainer className={"my-details"}>
            <PageSection className={"reference-details"}>
                <LoadingComponent isLoading={isLoading}>
                    <h1>{"My Details"}</h1>
                </LoadingComponent>
                {isLoading && !item ? (
                    <Container fluid className={"items-container"}>
                        <NameValueBox placeholder/>
                        <NameValueBox placeholder/>
                        <NameValueBox placeholder/>
                    </Container>
                ) : (
                    <Container fluid className={"items-container"}>
                        <NameValueBox name={"Your Reference"} value={item?.policyNumber}/>
                        <NameValueBox name={"Policy Number"} value={item?.externalReference}/>
                        <NameValueBox name={"Our Reference"} value={item?.number}/>
                    </Container>
                )}
            </PageSection>
            <PageSection className={"user-details"}>
                {isLoading && !item ? (
                    <Container fluid className={"items-container"}>
                        <NameValueBox placeholder/>
                        <NameValueBox placeholder/>
                        <NameValueBox placeholder/>
                        <NameValueBox placeholder/>
                    </Container>
                ) : (
                    <Container fluid className={"items-container"}>
                        <NameValueBox name={"Name"} value={item?.endUserName ?? ""}/>
                        <NameValueBox name={"Contact Details"} value={`${item?.endUserMobile}\n${item?.endUserEmail}`}/>
                        <NameValueBox name={"Address"} value={formatAddress(item?.registeredAddress)}/>
                        <div className={"wrapper"}>
                            <NameValueBox name={"Return Address"} value={formatAddress(item?.deliveryAddress)}/>
                            {addressChangeAllowed && (
                                <div className={"edit-address"}>
                                    <Button color="link"
                                            className={"modal-toggle"}
                                            onClick={toggleModal}
                                    >
                                        <FontAwesomeIcon icon="pencil-alt"/>&nbsp;&nbsp;
                                        {"Edit Address"}
                                    </Button>
                                </div>
                            )}
                            <StyledModal isOpen={modal} className={"address-modal"} toggle={toggleModal}>
                                <ModalHeader toggle={toggleModal}>
                                    {"Edit Return Address"}
                                </ModalHeader>
                                <ModalBody>
                                    <Form onSubmit={async e => { e.preventDefault(); await updateRegisteredAddress() }}>
                                        <AlertOnErrors simple errors={[updateErrors]} />
                                        <FormGroup>
                                            <Label>{'Address Line 1'}</Label>
                                            <Input type={"text"}
                                                   name={"address-line-1"}
                                                   placeholder={"Enter Address Line 1"}
                                                   onChange={e => change({ line1: e.currentTarget.value })}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>{'Address Line 2'}</Label>
                                            <Input type={"text"}
                                                   name={"address-line-2"}
                                                   placeholder={"Enter Address Line 2"}
                                                   onChange={e => change({ line2: e.currentTarget.value })}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>{'Address Line 3'}</Label>
                                            <Input type={"text"}
                                                   name={"address-line-3"}
                                                   placeholder={"Enter Address Line 3"}
                                                   onChange={e => change({ line3: e.currentTarget.value })}
                                            />
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col sm={6}>
                                                <Label>{'City'}</Label>
                                                <Input type={"text"}
                                                       name={"city"}
                                                       placeholder={"Enter City"}
                                                       onChange={e => change({ city: e.currentTarget.value })}
                                                />
                                            </Col>
                                            <Col sm={6}>
                                                <Label>{'Post Code'}</Label>
                                                <Input type={"text"}
                                                       name={"postcode"}
                                                       placeholder={"Enter Post Code"}
                                                       onChange={e => change({ postcode: e.currentTarget.value })}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup>
                                            <GDPRConsentFormPart validationErrors={validationErrors["gdprConsent"]}
                                                                 onChange={(checked) => change({gdprConsent: checked})}
                                                                 onBlur={() => validate("gdprConsent")}
                                                                 checked={model.gdprConsent}
                                            />
                                        </FormGroup>
                                        <FormButtons>
                                            <ButtonAsync type="submit"
                                                         color="primary"
                                                         isExecuting={isExecuting || isLoading}
                                                         executingChildren={<><Spinner size={"sm"} />{" Submitting..."}</>}>
                                                {"Confirm Return Address"}
                                            </ButtonAsync>
                                        </FormButtons>
                                    </Form>
                                </ModalBody>

                            </StyledModal>
                        </div>
                    </Container>
                )}
            </PageSection>
        </TrackedClaimContainer>
    )
}
