import './quote.scss';

import React from "react";
import {TrackedClaimContainer} from "../shared/TrackedClaimContainer";
import {PageSection} from "../../shared/pageSection/PageSection";
import {useCurrentClaimId} from "../../api/main/claims/useCurrentClaimId";
import {Table} from "reactstrap";
import {useClaimQuote} from "../../api/main/claims/useClaimQuote";
import {AlertOnErrors} from "../../shared/alertOnErrors";
import {ConditionalFragment} from "../../shared/conditionalFragment";
import {LoadingIndicator} from "../shared/LoadingIndicator";


export function Quote() {

    const claimId = useCurrentClaimId();

    const {data, isLoading, errors} = useClaimQuote({id: claimId});

    return (
        <TrackedClaimContainer className={"quote"}>
            <PageSection>
                <h1>Quote</h1>
            </PageSection>
            <PageSection>
                <AlertOnErrors errors={[errors]}/>
                <ConditionalFragment showIf={isLoading}>
                    <LoadingIndicator fullWidth/>
                </ConditionalFragment>
                <Table>
                    <thead>
                        <tr>
                            <th>{"Description"}</th>
                            <th>{"Cost (Ex VAT)"}</th>
                            <th>{"VAT"}</th>
                            <th>{"Total Cost (Inc VAT)"}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoading && data.item?.quote && (
                            data.item.quote.quoteDetails.map((detail, index) => {
                                if (!detail) return null;
                                const total = Number.parseFloat(detail.total);
                                const vat = Number.parseFloat(detail.vat);
                                const exVat = total - vat;
                                return (
                                    <tr key={index}>
                                        <th scope={"row"}>{detail.part?.name ?? index}</th>
                                        <td>{`£ ${exVat.toFixed(2)}`}</td>
                                        <td>{`£ ${detail.vat.toFixed(2)}`}</td>
                                        <td>{`£ ${detail.total.toFixed(2)}`}</td>
                                    </tr>
                                )
                            })
                        ) }
                        { data.item?.quote && (
                            <tr className={"totals"}>
                                <th scope={"row"}>{"Total"}</th>
                                <td>{(Number.parseFloat(data.item.quote.total) - Number.parseFloat(data.item.quote.vat)).toFixed(2)}</td>
                                <td>{Number.parseFloat(data.item.quote.vat).toFixed(2)}</td>
                                <td>{Number.parseFloat(data.item.quote.total).toFixed(2)}</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </PageSection>
        </TrackedClaimContainer>
    )

}