import gql from "graphql-tag";
import {dataFormDataFieldsFragment} from "./DataFormData";
import {eventHistoryFieldsFragement} from "./EventHistory";
import {quoteFieldsFragment} from "./Quote";
import {stateFieldsFragment} from "./State";
import {messageFieldsFragment} from "./Message";


export const claimFieldsFragment = gql`
    
    fragment claimFields on Claim {
        id,
        number,
        externalReference,
        policyNumber,
        lastStateChangeDate,
        stateDeadline,
        endUserName,
        endUserEmail,
        endUserMobile,
        endUserPhone,
        collectionAddress,
        deliveryAddress,
        nextCollectionAttemptDate,
        lastCollectionAttemptDate,
        nextDeliveryAttemptDate,
        lastDeliveryAttemptDate,
        accessories,
        accessoriesListed,
        dpdcollectionNumber,
        dpddeliveryNumber,
        collectionTrackingDetails,
        deliveryTrackingDetails,
        deliveryCourier,
        courier,
        excessPaymentTaken,
        excessTakenDate,
        policyExcessAmount,
        dataFormData {
            ...dataFormDataFields
        }
        events {
            ...eventHistoryFields
        }
        quote {
            ...quoteFields
        }
        state {
            ...stateFields
        }
        messages {
            ...messageFields
        }
    }
    
    ${dataFormDataFieldsFragment}
    ${eventHistoryFieldsFragement}
    ${quoteFieldsFragment}
    ${stateFieldsFragment}
    ${messageFieldsFragment}
`