import './passcodeEntryModal.scss';
import React from "react";
import {StyledModal} from "../shared/StyledModal";
import {
    Button,
    Form,
    FormGroup,
    Input,
    Label,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Spinner,
    TabContent,
    TabPane
} from "reactstrap";
import {useChanges} from "../../shared/useChanges";
import {ButtonAsync} from "reactstrap-buttonasync";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useHistory} from "react-router";
import {useSubmitPasscode} from "../../api/main/dataFormDatas/useSubmitPasscode";
import {AlertOnErrors} from "../../shared/alertOnErrors";
import {useValidatorCallback} from "pojo-validator-react";
import {useCurrentClaimId} from "../../api/main/claims/useCurrentClaimId";
import {PinEntry} from "./PinEntry";
import {PatternEntry} from "./PatternEntry";
import {GDPRConsentFormPart} from "../shared/GDPRConsentFormPart";


interface PasscodeEntryModel {
    passcode: string,
    claimId: string,
    passcodeType: 'pattern' | 'pin' | 'password',
    gdprConsent?: boolean,
}

export function PasscodeEntry(props: { onSubmitCallback?: (passcode?: string) => void }) {

    const claimId = useCurrentClaimId();

    // modal and tab states
    const history = useHistory();
    const [modal, setModal] = React.useState<boolean>(history.location.pathname.includes("/submit-passcode"));
    const [activeTab, setActiveTab] = React.useState<string>("password");
    const [showPassword, setShowPassword] = React.useState<boolean>(false);
    // we have to tell pattern lock that we activated it when we click on its tab
    // this fixes a bug where it was tracking mouse events even though it was not visible
    const [patternLockActive, setPatternLockActive] = React.useState<boolean>(false);


    // submit passcode mutation callback
    const [submitPasscodeMutation, { errors: submitErrors, isExecuting: isSubmitting }] = useSubmitPasscode();

    // model changes and validation
    const { model, change } = useChanges<PasscodeEntryModel>({ passcode: "", claimId: claimId ?? "", passcodeType: "password", gdprConsent: false})
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        console.log(`Validating: ${model.passcode}, ${model.passcodeType}`);
        const rules = {
            passcode: () => !model.passcode ? "Passcode is required" : "",
            gdprConsent: () => !model.gdprConsent ? 'Please tick this box if you consent to our Privacy Notice' : ''
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model])

    // toggle modals and tabs
    const toggleModal = () => setModal(!modal);
    const toggleShowPassword = () => setShowPassword(!showPassword);
    const toggleTab = (tab:  'password' | 'pattern' | 'pin') => {
        change({passcodeType: tab});
        setPatternLockActive(tab === "pattern");
        setActiveTab(tab);
    };

    const onPasscodeChange = (model: Partial<PasscodeEntryModel>) => {
        change({...model});
    }

    // validate model and submit passcode
    const submitPasscode = React.useCallback(async () => {
        if (!validate()) {
            return;
        }
        const {gdprConsent, ...rest} = model;

        await submitPasscodeMutation(rest);

        // close modal after we are done
        setModal(false);

    }, [validate, model, submitPasscodeMutation])


    return (
        <StyledModal className={"passcode-entry"}
                     isOpen={modal}
                     toggle={toggleModal}
                     onClosed={() => {
                         history.push(history.location.pathname.replace("/submit-passcode", ""));
                         if (props.onSubmitCallback) {
                             props.onSubmitCallback();
                         }
                     }}
        >

            <ModalHeader toggle={toggleModal}>
                {"Submit your device passcode"}
                <p>{"In order to progress your claim, we require your passcode for this device."}</p>
            </ModalHeader>
            <ModalBody>
                <AlertOnErrors errors={[submitErrors]} />
                <Nav tabs className={"flex-fill"}>
                    <NavItem>
                        <NavLink
                            className={`${activeTab === "password" ? "active" : ""}`}
                            onClick={() => {
                                toggleTab("password");
                            }}>
                            {"Password"}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={`${activeTab === "pin" ? "active" : ""}`}
                            onClick={() => {
                                toggleTab("pin");
                            }}>
                            {"PIN"}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={`${activeTab === "pattern" ? "active" : ""}`}
                            onClick={() => {
                                toggleTab("pattern");
                            }}>
                            {"Pattern"}
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={"password"}>
                        <Form onSubmit={async e => {  e.preventDefault(); console.log(model); await submitPasscode() }}>
                            <FormGroup>
                                <div className={"password-label-area"}>
                                    <Label>{"Please enter passcode"}</Label>
                                    <Button type={"button"}
                                            className={"show-password"}
                                            color={"secondary"}
                                            onClick={toggleShowPassword}
                                    >
                                        {showPassword ? (
                                            <>
                                                <FontAwesomeIcon icon={"eye-slash"} />
                                                {"  Hide password"}
                                            </>
                                        ) : (
                                            <>
                                                <FontAwesomeIcon icon={"eye"} />
                                                {"  Show password"}
                                            </>
                                        )}
                                    </Button>
                                </div>
                                <div className={"password-submit-area"}>
                                    <Input type={showPassword ? "text" : "password"}
                                                    name={"password"}
                                                    onChange={e => onPasscodeChange({ passcode: e.currentTarget.value , passcodeType: "password"})}
                                                    onBlur={e => validate('passcode')}
                                    />
                                    <Button type={"button"}
                                            className={"show-password-mobile"}
                                            color={"secondary"}
                                            onClick={toggleShowPassword}
                                    >
                                        {showPassword ? (
                                            <>
                                                <FontAwesomeIcon icon={"eye-slash"} />
                                                {"  Hide password"}
                                            </>
                                        ) : (
                                            <>
                                                <FontAwesomeIcon icon={"eye"} />
                                                {"  Show password"}
                                            </>
                                        )}
                                    </Button>
                                    <ButtonAsync className={"submit-button"}
                                                 type={"submit"}
                                                 color={"primary"}
                                                 isExecuting={isSubmitting}
                                                 executingChildren={<><Spinner size={"sm"} /></>}>
                                        {"Submit"}
                                    </ButtonAsync>
                                </div>
                            </FormGroup>
                        </Form>
                    </TabPane>
                    <TabPane tabId={"pin"}>
                        <Form onSubmit={async e => { e.preventDefault(); await submitPasscode() }}>
                            <PinEntry isLoading={isSubmitting} passcode={model.passcode} onChangeCallback={p => onPasscodeChange({passcodeType: "pin", passcode: p})} />
                        </Form>
                    </TabPane>
                    <TabPane tabId={"pattern"}>
                        <Form onSubmit={async e => { e.preventDefault(); await submitPasscode() }}>
                            <PatternEntry active={patternLockActive} isLoading={isSubmitting} onChangeCallback={p => onPasscodeChange({  passcode: p, passcodeType: "pattern" })} />
                        </Form>
                    </TabPane>
                </TabContent>
            </ModalBody>
            <ModalFooter>
                <div className={"security-policy"}>
                    <GDPRConsentFormPart validationErrors={validationErrors["gdprConsent"]}
                                         onChange={(checked) => change({gdprConsent: checked})}
                                         onBlur={() => validate("gdprConsent")}
                                         checked={model.gdprConsent}
                    />
                </div>
            </ModalFooter>

        </StyledModal>
    )
}
