import './dateView.scss';


import React from "react";
import moment from "moment";
import {LoadingIndicator} from "./LoadingIndicator";
import {ConditionalFragment} from "../../shared/conditionalFragment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle} from "@fortawesome/free-regular-svg-icons";

export interface DateViewProps {
    date?: moment.Moment,
    showTime?: boolean,
    size?: 'sm' | 'md' | 'lg'
    placeholder?: boolean,
    isLoading?: boolean,
    showNoDataIcon?: boolean
}


// Represents a date in a fancy way
export const DateView = (props: DateViewProps) => {

    const {
        date,
        placeholder = false,
        isLoading = false,
        showTime = false,
        showNoDataIcon = false,
        size = 'md'
    } = props;

    const year = date?.format("YYYY") ?? "";
    const month = date?.format("MMM") ?? "";
    const day = date?.format("DD") ?? "";
    const time = date?.format("HH:mm") ?? "";
    return (
        <div className={`date-view ${size}`}>
            {placeholder && !date ? (
                <div className={"no-data-placeholder"}>
                    <LoadingIndicator size={"sm"}/>
                </div>
            ) : (
                <>
                    {date ? (

                        <>
                            <div className={"month"}>{month}</div>
                            <div className={"day"}>{day}</div>
                            <ConditionalFragment showIf={size === 'lg'}>
                                <div className={"year"}>{year}</div>
                            </ConditionalFragment>
                            <ConditionalFragment showIf={showTime}>
                                <div className={"time"}>{time}</div>
                            </ConditionalFragment>
                        </>
                    ) : (

                        <ConditionalFragment showIf={showNoDataIcon}>
                            <div className={"no-data-placeholder"}>
                                <FontAwesomeIcon icon={faCircle}/>
                            </div>
                        </ConditionalFragment>
                    )}
                </>
            )}
        </div>
    )
}

