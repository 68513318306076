import moment from "moment";
import React from "react";
import { Alert, Container, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useClaimInspectionDate } from "../../api/main/claims/useClaimInspectionDate";
import { useCurrentClaimId } from "../../api/main/claims/useCurrentClaimId";
import { useGetInspectionPhotos } from "../../api/main/claims/useGetInspectionPhotosCallback";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { PageSection } from "../../shared/pageSection/PageSection";
import { useZipBlobCallback } from "../../shared/useZipData";
import { GalleryImage } from "../shared/GalleryImage";
import { LoadingComponent } from "../shared/LoadingComponent";
import { TrackedClaimContainer } from "../shared/TrackedClaimContainer";
import './photos.scss';



export function Photos() {

    const [showPlaceholders, setShowPlaceholders] = React.useState<boolean>(false);
    // get inspection photos from server
    const claimId = useCurrentClaimId();
    const { photos, loading: isLoading, errors } = useGetInspectionPhotos(claimId);
    // get inspection date
    const { data: { item }, errors: claimErrors, isLoading: claimIsLoading } = useClaimInspectionDate({ id: claimId });

    // zip callback
    const [zipPhotos, { errors: zipErrors, isExecuting: isZipping }] = useZipBlobCallback();

    function downloadBlob(blob: Blob) {
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = "inspection_photos";
        document.body.appendChild(a)
        a.click();
        document.body.removeChild(a)

        URL.revokeObjectURL(url);
    }

    React.useEffect(() => {
        setShowPlaceholders(isLoading && Object.keys(photos).length === 0);
    }, [photos, isLoading]);

    return (
        <TrackedClaimContainer className={"photos"}>
            <PageSection>
                <AlertOnErrors errors={[errors, claimErrors]} />
                <LoadingComponent isLoading={isLoading || claimIsLoading}>
                    <h1>{"Inspection Photos"}</h1>
                </LoadingComponent>
                {/* if loading and have no cached data, show placeholders */}
                {showPlaceholders ? (
                    <Container fluid className={"photo-gallery"}>
                        {Array.from({ length: 9 }).map((_, index) => {
                            return <GalleryImage
                                alt={"placeholder"}
                                placeholder={true}
                                key={index}
                            />
                        })}
                    </Container>
                ) :
                    /* if loaded and have no data, show message */
                    Object.keys(photos).length === 0 ? (
                        <Alert color={"secondary"}>{"Inspection photos for this claim have not yet been taken."}</Alert>
                    ) : (
                            /* if we have data, show pictures*/
                            <>
                                <div className={"inspection-date"}>
                                    {
                                        item?.inspectionStart?.eventDate ?
                                            `Inspection photos added ${moment(item.inspectionStart.eventDate).format("DD/MM/YYYY")}`
                                            : ""
                                    }
                                </div>
                                {(
                                    <Container fluid className={"photo-gallery"}>
                                        {Object.entries(photos).map(([name, url], index) => {
                                            return <GalleryImage
                                                url={url}
                                                alt={name}
                                                key={index}
                                            />
                                        })}
                                    </Container>
                                )}
                            </>
                        )
                }
            </PageSection>
        </TrackedClaimContainer>
    )

}