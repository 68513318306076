import './report.scss';

import {BlobProvider, pdf} from '@react-pdf/renderer';
import moment from "moment";
import React from "react";
import {Col, Container, Row, Spinner, Table} from "reactstrap";
import {ButtonAsync} from "reactstrap-buttonasync";
import {useCurrentClaimId} from "../../api/main/claims/useCurrentClaimId";
import {useMyClaimDetails} from "../../api/main/claims/useMyClaimDetails";
import {AlertOnErrors} from "../../shared/alertOnErrors";
import {PageSection} from "../../shared/pageSection/PageSection";
import {LoadingComponent} from "../shared/LoadingComponent";
import {TrackedClaimContainer} from "../shared/TrackedClaimContainer";
import {PdfReport} from './PdfReport';
import {useFormattedAddressCallback} from "../../shared/useFormattedAddressCallback";
import {useAsyncCallback} from "react-use-async-callback";


export interface InspectionItem {
    sharedKey?: string | null,
    name: string,
    value: string
}


export interface ReportModel {
    claimNumber: string,
    policyNumber: string,
    jobReference: string,
    inspectionStart?: moment.Moment | null,
    inspectionEngineer: string,
    endUserName: string,
    endUserAddress: string,
    endUserPhone: string,
    accessories: string[],
    equipmentType: string,
    deviceInformation: { [key: string]: InspectionItem },
    inspectionInformation: { [key: string]: InspectionItem },
}


const ReportRow = (props: { name: string, value?: string, displayEmpty?: boolean }) => {
    const { name: header, value, displayEmpty = false } = props;

    const display = displayEmpty || (value && value !== "")

    if (!display) return (<></>)
    return (
        <tr className={"report-row"}>
            <th>{header}</th>
            <td>{value}</td>
        </tr>
    )
}


export function Report() {
    const claimId = useCurrentClaimId();

    const [pdfError, setPdfError] = React.useState<Error | null>(null);
    const [model, setModel] = React.useState<ReportModel | null>(null);

    const deviceDetails = [
        "Manufacturer",
        "Model",
        "Colour",
        "Serial Number",
        "IMEI",
        "CPU",
        "Processor",
        "RAM",
        "Memory Size",
    ]

    const inspectionDetails = [
        "Inspection Type 1",
        "Inspection Type 2",
        "Inspection Type 3",
        "Repair Action Needed",
    ]

    // get data forms
    const { data: { item }, isLoading, errors } = useMyClaimDetails({ id: claimId });
    const formatAddress = useFormattedAddressCallback();

    // generate pdf callback
    const [generatePdf, {errors: pdfErrors, isExecuting}] = useAsyncCallback(async () => {
        if (!model) return;
        const blob = await pdf(<PdfReport {...model} />).toBlob();
        const url = URL.createObjectURL(blob);
        window.open(url, "__blank");
    }, [model])

    // generate report model from data
    React.useEffect(() => {

        if (!item) return;

        const inspectionData: { [key: string]: InspectionItem } = {};
        const deviceData: { [key: string]: InspectionItem } = {};

        // parse and add data form information to relevant fields
        if (item?.dataFormData) {

            // extract relevant data from data form
            for (let dataItem of item.dataFormData) {
                let name = dataItem?.name;
                if (!name && name !== null) continue;

                // change name of inspection type to something more user friendly
                let displayName = name;
                if (name.includes("Inspection Type ")) {
                    displayName = name.replace("Inspection Type ", "Fault Diagnosis ");
                }

                // create model item and add it to the relevant list
                const inspectionItem: InspectionItem = {
                    name: displayName,
                    sharedKey: dataItem?.sharedDataKey,
                    value: dataItem?.stringValue ?? dataItem?.decimalValue
                }

                if (inspectionDetails.includes(name)) inspectionData[name] = inspectionItem;
                if (deviceDetails.includes(name)) deviceData[name] = inspectionItem;
            }
        }

        let engineerName = "";
        if (item.inspectionStart?.engineer) {
            const split = item.inspectionStart.engineer.split(" ");
            const firstName = split[0];
            const lastInitial = split[1] ? split[1][0] : "";
            engineerName = `${firstName} ${lastInitial}`
        }

        // set our report model
        setModel({
            claimNumber: item.number,
            policyNumber: item.policyNumber,
            jobReference: item.externalReference,
            inspectionStart: item.inspectionStart?.eventDate ? moment(item.inspectionStart.eventDate) : null,
            inspectionEngineer: engineerName ?? "",
            endUserName: item.endUserName ?? "",
            endUserAddress: formatAddress(item.registeredAddress),
            endUserPhone: item.endUserMobile ?? item.endUserPhone,
            accessories: item.accessories ? item.accessories.split("\n") : [],
            equipmentType: item.equipmentType?.name ?? "",
            deviceInformation: deviceData ?? {} as any,
            inspectionInformation: inspectionData ?? {} as any,
        });

    }, [item]);


    return (
        <TrackedClaimContainer className={"report"}>
            <PageSection>
                <AlertOnErrors errors={[errors]} />
                <LoadingComponent isLoading={isLoading}>
                    <h1>Inspection Report</h1>
                </LoadingComponent>
            </PageSection>
            {model && (
                <>
                    <PageSection className={"alternative"}>
                        <Container>
                            <Row className={"report-section"}>
                                <Col md={6} sm={12}>
                                    <Table borderless>
                                        <tbody>
                                            <ReportRow name={"Customer"} value={model.endUserName} />
                                            <ReportRow name={"Address"} value={model.endUserAddress} />
                                            <ReportRow name={"Contact"} value={model.endUserPhone} />
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col md={6} sm={12}>
                                    <Table borderless>
                                        <tbody>
                                            <ReportRow name={"Inspection Engineer"} value={model.inspectionEngineer} />
                                            <ReportRow name={"Inspection Date"} value={model.inspectionStart?.format("DD/MM/YYYY")} />
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Container>
                    </PageSection>
                    <PageSection className={"claim-references"}>
                        <Container>
                            <Row className={"report-section"}>
                                <Col md={6} sm={12}>
                                    <Table borderless>
                                        <tbody>
                                            <ReportRow name={"Greenbridge Ref"} value={model.claimNumber}/>
                                            <ReportRow name={"Policy Number"} value={model.policyNumber}/>
                                            <ReportRow name={"Job Number"} value={model.jobReference}/>
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col md={6} sm={12}>
                                    <Table borderless>
                                        <tbody>
                                            <tr>
                                                <th>{"Device Accessories Present"}</th>
                                            </tr>
                                            <tr>
                                                <td>{
                                                    model.accessories.length ?
                                                        <ul>
                                                            {model.accessories.map((a, i) => {
                                                                return <li key={i}>{a}</li>
                                                            })}
                                                        </ul>
                                                        : "None"
                                                }</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Container>
                    </PageSection>
                    <PageSection className={"alternative"}>
                        <Container>
                            <Row className={"report-section"}>
                                <Col md={6} sm={12}>
                                    <Table borderless>
                                        <tbody>
                                            <ReportRow name={"Equipment Type"} value={model.equipmentType}/>
                                            <ReportRow name={"Manufacturer"} value={model.deviceInformation["Manufacturer"]?.value}/>
                                            <ReportRow name={"Model"} value={model.deviceInformation["Model"]?.value}/>
                                            <ReportRow name={"Serial Number"} value={model.deviceInformation["Serial Number"]?.value}/>
                                            <ReportRow name={"IMEI"} value={model.deviceInformation["IMEI"]?.value}/>
                                            <ReportRow name={"Colour"} value={model.deviceInformation["Colour"]?.value}/>
                                            <ReportRow name={"CPU"} value={model.deviceInformation["CPU"]?.value}/>
                                            <ReportRow name={"Processor"} value={model.deviceInformation["Processor"]?.value}/>
                                            <ReportRow name={"RAM"} value={model.deviceInformation["RAM"]?.value}/>
                                            <ReportRow name={"Memory Size"} value={model.deviceInformation["Memory Size"]?.value}/>
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col md={6} sm={12}>
                                    <Table borderless>
                                        <tbody>
                                            <ReportRow name={"Repair Action Needed"} value={model.inspectionInformation["Repair Action Needed"]?.value}/>
                                            <ReportRow name={"Fault Diagnosis"} value={model.inspectionInformation["Inspection Type 1"]?.value}/>
                                            <ReportRow name={"Fault Diagnosis 2"} value={model.inspectionInformation["Inspection Type 2"]?.value}/>
                                            <ReportRow name={"Fault Diagnosis 3"} value={model.inspectionInformation["Inspection Type 3"]?.value}/>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Container>
                    </PageSection>
                    <PageSection className={"button-container"}>
                        <AlertOnErrors errors={[pdfError]} />
                        <ButtonAsync color={"primary"}
                            onClick={async e => await generatePdf()}
                            isExecuting={isExecuting}
                            executingChildren={<><Spinner size={"sm"} />{" Generating pdf..."}</>}
                        >
                            {"Download Inspection Report"}
                        </ButtonAsync>
                    </PageSection>
                </>
            )}

        </TrackedClaimContainer>
    )
}


