import './history.scss';

import React from "react";
import {TrackedClaimContainer} from "../shared/TrackedClaimContainer";
import {PageSection} from "../../shared/pageSection/PageSection";
import {useCurrentClaimId} from "../../api/main/claims/useCurrentClaimId";
import {NameValueBox} from "../../shared/nameValueView/NameValueBox";
import {useClaimEventHistories} from "../../api/main/claims/useClaimEventHistories";
import {AlertOnErrors} from "../../shared/alertOnErrors";
import {HistoryItem} from "./HistoryItem";
import {claimEventsQuery_claim_events} from "../../api/main/generated/claimEventsQuery";
import moment from "moment";
import {LoadingComponent} from "../shared/LoadingComponent";


export function History() {

    const claimId = useCurrentClaimId();
    const [displayData, setDisplayData] = React.useState<(claimEventsQuery_claim_events | null)[]>([]);

    // get events
    const {data, isLoading, errors} = useClaimEventHistories({id: claimId});

    // sort and filter events we want
    React.useEffect(() => {
        if (data.claim?.events){

            let events = data.claim.events.filter((e) => {
                return e?.name.includes("state changed to") ||
                    e?.name.toLowerCase().includes("Collection Arranged")
            })

            events.sort((a, b) => {
                if (!a?.eventDate || !b?.eventDate) return 0;
                let momentA = moment(a.eventDate);
                let momentB = moment(b.eventDate);
                return momentA.isBefore(momentB)
                    ? 1
                    : momentA.isAfter(momentB) ? -1 : 0;
            });

            // sort by date descending
            setDisplayData(events);

        }
    }, [data])

    return (
        <TrackedClaimContainer className={"history"}>
            <AlertOnErrors errors={[errors]}/>
            <PageSection className={"header"}>
                <LoadingComponent isLoading={isLoading}>
                    <h1>{"History"}</h1>
                </LoadingComponent>
                <NameValueBox inline
                              placeholder={isLoading && !displayData.length}
                              size={"sm"}
                              name={"Last Updated"}
                              value={displayData ?
                                  moment(displayData[0]?.eventDate).format("DD/MM/YYYY HH:mm") : ""
                              }/>
            </PageSection>
            <PageSection className={"history-table"}>
                { isLoading && !displayData.length ? (
                        <>
                            <HistoryItem placeholder/>
                            <HistoryItem placeholder/>
                            <HistoryItem placeholder/>
                            <HistoryItem placeholder/>
                            <HistoryItem placeholder/>
                            <HistoryItem placeholder/>
                            <HistoryItem placeholder/>
                            <HistoryItem placeholder/>
                            <HistoryItem placeholder/>
                            <HistoryItem placeholder/>
                        </>
                    ) :
                    displayData.map((e, index) => {
                        return <HistoryItem key={index} text={e?.name} date={moment(e?.eventDate)}/>;
                    })
                }
            </PageSection>
        </TrackedClaimContainer>
    )

}