import gql from "graphql-tag";


export const contractFieldsFragment = gql`
    fragment contractFields on Contract {
        id,
        name,
        customer,
        tatSlaDays
    }
`
