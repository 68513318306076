import React from "react";
import {useLazyQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {claimFieldsFragment} from "../models/Claim";
import {stateFieldsFragment} from "../models/State";
import {contractFieldsFragment} from "../models/Contract";
import {mainApiConfig} from "../../../configure/mainApiConfig";
import {AsyncLoadResult} from "../../../shared/abstractStore";
import {eventHistoryFieldsFragement} from "../models/EventHistory";
import moment from "moment";
import {ClaimQueryOptions} from "./useMyClaim";
import {claimProgressDataQuery} from "../generated/claimProgressDataQuery";
import {eventHistoryFields} from "../generated/eventHistoryFields";


export interface ClaimProgressData {
    stateName: string,
    stateDescription: string
    startDate?: moment.Moment,
    endDate?: moment.Moment,
    progressStep?: number,
    event?: Partial<eventHistoryFields>
}

export interface ClaimProgressResult {
    initialStates: ClaimProgressData[],
    progressStates: ClaimProgressData[],
    finalStates: ClaimProgressData[],
    receiveDate?: moment.Moment,
    nextCollection?: moment.Moment,
    nextDelivery?: moment.Moment,
    contractSlaDays?: number
}


/**
 * get claim progress information data sorted by start date and split into
 * 3 progress stages
 * @see ClaimProgressData
 */
export function useClaimProgressData(options: ClaimQueryOptions) : AsyncLoadResult<ClaimProgressResult> {

    // get claims state and all claim events
    const [load, {loading: dataLoading, error, data, refetch}] = useLazyQuery<claimProgressDataQuery>(
        gql`
            query claimProgressDataQuery($claimId: String) {
                item : claim (id: $claimId) {
                    ...claimFields
                    state {
                        ...stateFields
                    }
                    contract {
                        ...contractFields
                    }
                    events {
                        ...eventHistoryFields
                    }
                }

            }
            ${claimFieldsFragment}
            ${stateFieldsFragment}
            ${contractFieldsFragment}
            ${eventHistoryFieldsFragement}
        `,
        {
            variables: {claimId: options.id},
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options?.onCompleted(data);
                }
            }
        }
    )

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])


    function sortfn(a: ClaimProgressData, b: ClaimProgressData) {
        if (!a?.progressStep || !b?.progressStep) return 0;
        return a.progressStep > b.progressStep ? 1 : -1;
    }

    const parseData = React.useCallback((): ClaimProgressResult | undefined => {
        if (data?.item) {
            // we need to sort our states into 3 categories
            const initialStates: ClaimProgressData[] = [];
            const progressStates: ClaimProgressData[] = [];
            const finalStates: ClaimProgressData[] = [];
            let receiveEvent: moment.Moment | undefined = undefined;

            data.item.events
                .filter((e) => e && e.relatedState)
                .forEach((e) => {

                        // go through all events and sort them into state groups
                        if (e?.relatedState && e?.relatedState.name !== "") {

                            // find receive event
                            if (e.relatedState.systemName === "Equipment Received") {
                                receiveEvent = moment(e.eventDate);
                            }

                            if (e.relatedState.trackerNumber === 1) {
                                initialStates.push({
                                    stateName: e?.relatedState?.name ?? "",
                                    stateDescription: e?.relatedState?.description ?? "",
                                    startDate: e?.eventDate ? moment(e.eventDate) : undefined,
                                    progressStep: e.relatedState.trackerStep,
                                    event: e
                                })
                            } else if (e.relatedState.trackerNumber === 2) {
                                progressStates.push({
                                    stateName: e?.relatedState?.name ?? "",
                                    stateDescription: e?.relatedState?.description ?? "",
                                    startDate: e?.eventDate ? moment(e.eventDate) : undefined,
                                    progressStep: e.relatedState.trackerStep,
                                    event: e
                                })
                            } else if (e.relatedState.trackerNumber === 3) {
                                finalStates.push({
                                    stateName: e?.relatedState?.name ?? "",
                                    stateDescription: e?.relatedState?.description ?? "",
                                    startDate: e?.eventDate ? moment(e.eventDate) : undefined,
                                    progressStep: e.relatedState.trackerStep,
                                    event: e
                                })
                            }
                        }
                    }
                )


            // sort by date ascending
            initialStates.sort(sortfn);
            progressStates.sort(sortfn);
            finalStates.sort(sortfn);

            return {
                finalStates: finalStates,
                initialStates: initialStates,
                progressStates: progressStates,
                nextCollection: moment(data.item.nextCollectionAttemptDate),
                nextDelivery: moment(data.item.nextCollectionAttemptDate),
                contractSlaDays: data.item?.contract?.tatSlaDays,
                receiveDate: receiveEvent,
            }
        }
    }, [data])

    const refresh = React.useCallback(async () => {
        !refetch ? await load() : await refetch();
    }, [refetch, load]);

    return React.useMemo(() => {
        if (!data?.item){
            return {
                data: data ?? {finalStates: [], progressStates: [], initialStates: [], item: null} as any,
                refresh: refresh,
                isLoading: dataLoading,
                errors: error
            }
        }
        return {
            data: parseData(),
            refresh: refresh,
            isLoading: dataLoading,
            errors: error
        }

    }, [parseData, data, refresh, dataLoading, error]);
}
