import * as React from 'react';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { MainContainer } from './MainContainer';
import { Banner } from './Banner';
import { Background } from './Background';

/**
 * Component to show when a route is not found. 
 */
export const NotFound = () => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <Background>
            <Banner>
                <h1>{t('notFound.notFoundHeading', 'Sorry the page you have requested cannot be found')}</h1>
            </Banner>
            <MainContainer>
                <p>
                    {t('notFound.helpText', 'The link you have acccessed is for a page that could not be found.  Please check and try again, or try navigating to the page you want using the apps menus.')}
                </p>

                <Button color="primary" style={{height: "50px"}} outline onClick={() => history.goBack()}>
                    {t('notFound.goBack', 'Go back to the previous page')}
                </Button>
            </MainContainer>
        </Background>
        );
};