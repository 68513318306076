import './galleryImage.scss';

import React from "react";
import {Button, Media, ModalBody, ModalFooter} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {NameValueBox} from "../../shared/nameValueView/NameValueBox";
import {StyledModal} from "./StyledModal";
import {LoadingIndicator} from "./LoadingIndicator";
import {ConditionalFragment} from "../../shared/conditionalFragment";


export interface GalleryImageProps {
    alt: string,
    base64?: string,
    url?: string,
    captionHeader?: string,
    captionValue?: string,
    placeholder?: boolean
}

export function GalleryImage(props: GalleryImageProps){
    const {base64, alt, url, captionHeader = "", captionValue = "", placeholder = false} = props;

    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [modal, setModal] = React.useState<boolean>(false);

    const toggleModal = () => setModal(!modal);

    function downloadImage() {

        const a = document.createElement("a");
        a.href = url ?? '';
        a.download = alt;
        document.body.appendChild(a)
        a.click();
        document.body.removeChild(a)
    }

    return (
        <div className={"image-container"} onClick={toggleModal}>
            {
                url ? (
                    <img className={"image"} src={url} alt={alt} onLoad={() => setLoaded(true)} />
                ): 
                base64 && base64 !== "" && (
                <img className={"image"} src={`data:image/png;base64,${base64 ?? ""}`} alt={alt} onLoad={() => setLoaded(true)} />
            )}
              {(!loaded || placeholder) && (
                  <div className={"image placeholder"}>
                      <ConditionalFragment showIf={!!base64 && base64 !== ""}>
                          <LoadingIndicator fullWidth size={"sm"}/>
                      </ConditionalFragment>
                      <div className={"shape1"}/>
                      <div className={"shape2"}/>
                  </div>
            )}
            { loaded && !placeholder && (
                <>
                    <div className={"overlay"}>
                        <Button color={"primary"} onClick={(e) => {e.stopPropagation(); downloadImage();}}>
                            <FontAwesomeIcon icon={"download"}/>
                        </Button>
                    </div>
                    <StyledModal className={"image-modal"} isOpen={modal} toggle={toggleModal}>
                        <ModalBody>
                            <div className={"modal-image-container"}>
                                <Media className={"modal-image"} object src={
                                    base64 ? `data:image/png;base64,${base64}`
                                        : url
                                } alt={alt} />
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <div className={"wrapper"}>
                                <NameValueBox size={"sm"} name={captionHeader} value={captionValue}/>
                                <Button color={"primary"} onClick={downloadImage}>
                                    {"Download Photo"}
                                    <FontAwesomeIcon icon={"download"}/>
                                </Button>
                            </div>
                        </ModalFooter>
                    </StyledModal>
                </>
            )}
        </div>
    )
}