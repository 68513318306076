import * as React from 'react';
import {NavLink, NavLinkProps} from 'react-router-dom';

export interface NavLinkTrackActiveProps extends NavLinkProps {
}

/**
 * NavLink from react-router configured to track the active component with the active class. 
 * 
 * This simply returns a <NavLink> that is preconfigured with activeClassName="active".
 */
export const NavLinkTrackActive = (props: NavLinkTrackActiveProps) => {
    const { to, exact, activeClassName, ...rest } = props;

    return (
        <NavLink to={to} exact={exact ?? to === '/'} activeClassName={activeClassName ?? 'active'} {...rest} />
        );
};

