import './claimStatus.scss'
import React from "react";
import {Button, Col, Row} from "reactstrap";
import {DateProgress} from "./DateProgress";
import {useHistory} from "react-router";
import {AlertOnErrors} from "../../shared/alertOnErrors";
import {PageSection} from "../../shared/pageSection/PageSection";
import {ClaimProgressData, useClaimProgressData} from "../../api/main/claims/useClaimProgressData";
import {LoadingComponent} from "../shared/LoadingComponent";
import {useClaimReadyByDate} from "../../api/main/claims/useClaimReadyByDate";
import moment from "moment";


export function ClaimStatus(props: {claimId?: string}) {
    const history = useHistory();

    // these are last states for each of the 3 state categories to be displayed in the 3 progress bars
    const [initialStep, setInitialStep] = React.useState<ClaimProgressData|null>(null);
    const [progressStep, setProgressStep] = React.useState<ClaimProgressData|null>(null);
    const [finalStep, setFinalStep] = React.useState<ClaimProgressData|null>(null);

    const {data, isLoading, errors: loadingErrors} = useClaimProgressData({id: props.claimId})
    const readyByDate = useClaimReadyByDate({
        claimId: props.claimId,
        dateReceived: data.receiveDate,
        finalEvent: finalStep?.event,
        contractSlaDays: data.contractSlaDays ?? 0,
        collectionDate: data.nextCollection
    })

    React.useEffect(() => {
        if (data && readyByDate){

            // get last events for each of the 3 stages, or create empty events if they are missing
            let lastInitial = data.initialStates[data.initialStates.length - 1] ?? {stateName: "Collection", stateDescription: "Pending", progressStep: 0};
            let lastProgress = data.progressStates[data.progressStates.length - 1] ?? {stateName: "Inspection", stateDescription: "Pending", progressStep: 0};
            let lastFinal = data.finalStates[data.finalStates.length - 1] ?? {stateName: "Completion", stateDescription: "Pending", progressStep: 0};

            // if next state has events, previous must be completed, unless claim is cancelled
            if (lastProgress.startDate && lastFinal.event?.relatedState?.systemName !== 'Claim Cancelled') {
                lastInitial.progressStep = 100;
                lastInitial.stateDescription = "Completed";
            }

            if (lastFinal.startDate && lastFinal.event?.relatedState?.systemName !== 'Claim Cancelled') {
                lastProgress.progressStep = 100;
                lastProgress.stateDescription = "Completed";
            }
            // for the final progress bar display completed event date or SLA date
            if (lastFinal?.progressStep !== 100) {
                if (!lastFinal) lastFinal = {stateName: "Completion", stateDescription: "Pending", progressStep: 0};
                lastFinal.startDate = moment(readyByDate);
            }

            // if the progress step does not have a date, give it a
            // half-way date between initial and final
            if (!lastProgress.startDate && lastInitial.startDate) {
                lastProgress = {stateName: "Inspection", stateDescription: "Pending", progressStep: 0, startDate: moment(lastInitial.startDate).add("3", "days")}
            }

            // handle claim cancelled
            if (lastFinal.event?.relatedState?.systemName === 'Claim Cancelled') {
                lastInitial = {stateName: "Collection", stateDescription: "Pending", progressStep: 0};
                lastProgress = {stateName: "Inspection", stateDescription: "Pending", progressStep: 0};
                lastFinal = {stateName: "Completion", stateDescription: "Your claim has been cancelled", progressStep: 0};
            }

            setInitialStep(lastInitial);
            setProgressStep(lastProgress);
            setFinalStep(lastFinal);
        }

    }, [data, readyByDate])

    return (
        <PageSection className={"claim-status"}>
            <AlertOnErrors errors={[loadingErrors]} />
            <LoadingComponent isLoading={isLoading}>
                <h1>Claim Status</h1>
            </LoadingComponent>
            <Row>
                <Col md={12} lg={4}>
                    <DateProgress placeholder={isLoading && !initialStep}
                                  isLoading={isLoading}
                                  step={initialStep?.stateName ?? "Collection"}
                                  description={initialStep?.stateDescription ?? "Pending"}
                                  dateStart={initialStep?.startDate}
                                  dateEnd={initialStep?.endDate}
                                  progressAmount={initialStep?.progressStep}
                    />
                </Col>
                <Col md={12} lg={4}>
                    <DateProgress placeholder={isLoading && !progressStep}
                                  isLoading={isLoading}
                                  step={progressStep?.stateName ?? "Inspection"}
                                  description={progressStep?.stateDescription ?? "Pending"}
                                  dateStart={progressStep?.startDate}
                                  progressAmount={progressStep?.progressStep}
                    />
                </Col>
                <Col md={12} lg={4}>
                    <DateProgress placeholder={isLoading && !finalStep}
                                  isLoading={isLoading}
                                  step={finalStep?.stateName ?? "Completion"}
                                  description={finalStep?.stateDescription ?? "Pending"}
                                  dateStart={finalStep?.startDate}
                                  progressAmount={finalStep?.progressStep}
                    />
                </Col>
            </Row>
            <Row>
                <div className={"button-container"}>
                    <Button color={"primary"} onClick={() => history.push(`/report/${props.claimId}`)}>
                        Inspection Report
                    </Button>
                    <Button color={"primary"} onClick={() => history.push(`/photos/${props.claimId}`)}>
                        Device Photos
                    </Button>
                </div>
            </Row>
        </PageSection>
    )

}
