import {AsyncLoadOptions, AsyncLoadResult} from "../../../shared/abstractStore";
import {useLazyQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {mainApiConfig} from "../../../configure/mainApiConfig";
import React from "react";
import {contractFieldsFragment} from "../models/Contract";
import {dataFormDataFieldsFragment} from "../models/DataFormData";
import {equipmentTypeFieldsFragment} from "../models/EquipmentType";
import {myClaimSupportingDetailsQuery} from "../generated/myClaimSupportingDetailsQuery";
import {eventHistoryFieldsFragement} from "../models/EventHistory";
import {ClaimQueryOptions} from "./useMyClaim";


/**
 * Get claim and related state and spec details
 */
export function useMyClaimDetails(options: ClaimQueryOptions): AsyncLoadResult<myClaimSupportingDetailsQuery> {

    const [load, {loading, error, data, refetch}] = useLazyQuery(
        gql`
            query myClaimSupportingDetailsQuery($id: String!){
                item: claim(id: $id){
                    id,
                    number,
                    externalReference,
                    policyNumber,
                    endUserName,
                    endUserEmail,
                    endUserMobile,
                    endUserPhone,
                    registeredAddress,
                    accessoriesListed,
                    accessories,
                    excessPaymentTaken,
                    excessTakenDate,
                    policyExcessAmount,
                    contract {
                        ...contractFields
                    }
                    equipmentType {
                        ...equipmentTypeFields
                    }
                    dataFormData {
                        ...dataFormDataFields
                    }
                    collectionArranged: claimEvent(name: "Collection Arranged") {
                        ...eventHistoryFields
                    }
                    equipmentReceived: claimEvent(name: "Claim state changed to Equipment Received.") {
                        ...eventHistoryFields
                    }
                    inspectionStart: claimEvent(name: "Claim state changed to Inspection.") {
                        ...eventHistoryFields
                    }
                    repairStart: claimEvent(name: "Claim state changed to Repair.") {
                        ...eventHistoryFields
                    }
                    awaitingReturn: claimEvent(name: "Claim state changed to Awaiting Repaired Equipment Return to End User.") {
                        ...eventHistoryFields
                    }
                    equipmentReturned: claimEvent(name: "Claim state changed to Repaired Equipment Returned to End User.") {
                        ...eventHistoryFields
                    }
                    awaitingVoucher: claimEvent(name: "Claim state changed to Awaiting Voucher Issue.") {
                        ...eventHistoryFields
                    }
                    voucherIssued: claimEvent(name: "Claim state changed to Voucher Issued to End User.") {
                        ...eventHistoryFields
                    }
                    awaitingAuthorisation: claimEvent(name: "Claim state changed to Awaiting Authorisation.") {
                        ...eventHistoryFields
                    }
                    awaitingActivationLockRemoval: claimEvent(name: "Claim state changed to Awaiting Activation Lock Removal.") {
                        ...eventHistoryFields
                    }
                }
            }
            ${contractFieldsFragment} 
            ${dataFormDataFieldsFragment} 
            ${equipmentTypeFieldsFragment}
            ${eventHistoryFieldsFragement}
        `, {
            variables: {id: options.id},
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted){
                    options.onCompleted(data);
                }
            }
        }
    )

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    const refresh = React.useCallback(
        async () => {!refetch ? await load() : await refetch();},
        [refetch, load]);

    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);

}
