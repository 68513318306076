import React from "react";
import {LoginContainer} from "../shared/LoginContainer";
import {Banner} from "../shared/Banner";
import {Form, FormGroup, Label, Spinner} from "reactstrap";
import {ButtonAsync} from "reactstrap-buttonasync";
import {ValidatedInput} from "pojo-validator-reactstrap";
import {FormButtons} from "../shared/FormButtons";
import {useChanges} from "../../shared/useChanges";
import {useValidatorCallback} from "pojo-validator-react";
import {useAuthorizeEndUserCallback} from "../../api/account/useAuthorizeEndUserCallback";
import {AlertOnErrors} from "../../shared/alertOnErrors";


interface TrackMyClaimModel {
    reference: string,
    postcode: string
}

// End user access component
export const TrackMyClaim = () => {
    const [authorize, { isExecuting: isLoading, errors: authorizationErrors }] = useAuthorizeEndUserCallback();

    const { model, change } = useChanges<TrackMyClaimModel>({ postcode: '', reference: '' });

    /**
     * Validate the model before trying to use it.
     */
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            email: () => !model.reference ? 'Claim reference is required' : '',
            password: () => !model.postcode ? 'You postcode is required' : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    /**
     * Perform a login by and handle the result.
     */
    const [isDoingFullPageRedirect, setIsDoingFullPageRedirect] = React.useState<boolean>(false);
    const performLogin = React.useCallback(async (): Promise<void> => {
        if (!validate()) {
            return;
        }

        const result = await authorize(model);

        if (result && result.succeeded && result.claimId){
            setIsDoingFullPageRedirect(true)
            // const returnUrl = (result.returnUrl === "/" ? "/my-claim/" : result.returnUrl) + `${result.claimId}`;
            // TODO: disabled this because it will keep redirecting to an invalid url if we arrived here by invalid url
            window.location.href = `/my-claim/${result.claimId}`;
        }

    }, [authorize, validate, model]);

    // Render the UI.
    return (
        <>
            <LoginContainer>
                <Banner>
                    <h1>{'Track your claim'}</h1>
                </Banner>
                <Form onSubmit={async e => { e.preventDefault(); await performLogin(); }}>
                    <AlertOnErrors simple errors={[authorizationErrors]} />
                    <FormGroup>
                        <Label>{'Policy Number / Reference'}</Label>
                        <ValidatedInput type="text" name="reference" value={model.reference}
                                        placeholder={"Your Policy Number / Reference"}
                                        onChange={e => change({ reference: e.currentTarget.value })}
                                        onBlur={e => validate('reference')}
                                        validationErrors={validationErrors['reference']} />
                    </FormGroup>
                    <FormGroup>
                        <Label>{'Post Code'}</Label>
                        <ValidatedInput type="text" name="postcode"
                                        value={model.postcode} placeholder={"Your Post Code"}
                                        onChange={e => change({ postcode: e.currentTarget.value })}
                                        onBlur={e => validate('postcode')}
                                        validationErrors={validationErrors['postcode']} />
                    </FormGroup>

                    <FormButtons>
                        <ButtonAsync type="submit" color="primary"
                                     isExecuting={isLoading || isDoingFullPageRedirect}
                                     executingChildren={<><Spinner size="sm" /> {'Finding your claim...'}</>}>
                            {'Track Claim'}
                        </ButtonAsync>
                    </FormButtons>
                </Form>
            </LoginContainer>
        </>
    );
};

