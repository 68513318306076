import {Point} from "./types";
import React from "react";


/**
 *  convert client coordinates to canvas (parent element) coordinates
 */
export function clientPointToCanvas(canvasRef: React.RefObject<HTMLDivElement>, { x, y }: Point): Point | undefined {
  if (canvasRef?.current) {
    const rect = canvasRef.current.getBoundingClientRect();
    if (x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom) {
      return { x: x - rect.left, y: y - rect.top }
    }
  }
}


/**
 * get top-left coordinates of each node and return as an array
 * @param nodeSize: size in pixels of a node element
 * @param wrapperWidth: width in pixes of the pattern lock area
 * @param patternSize: number of nodes that exist in the row or col of the pattern (always equal)
 */
export function getNodePoints(nodeSize: number, wrapperWidth: number, patternSize: number): Point[] {

  // center of node
  const nodeHalfSize = nodeSize / 2;
  // surrounding area of the node
  const nodeAreaSize = wrapperWidth / patternSize;
  // center of the surrounding area of the node
  const halfNodeArea = nodeAreaSize / 2;

  return Array.from({ length: patternSize ** 2 }).map((_, i) => ({
      x: ((nodeAreaSize * (i % patternSize)) + halfNodeArea) - nodeHalfSize,
      y: ((nodeAreaSize * Math.floor(i / patternSize)) + halfNodeArea) - nodeHalfSize
  }));
}


export function getConnectorPoint({ x, y }: Point, nodeSize: number, connectorThickness: number): Point {
  return {
      x: x + Math.floor(nodeSize / 2),
      y: y + Math.floor(nodeSize / 2) - Math.floor(connectorThickness / 2)
  }
}

/**
* Check collision against pattern nodes. Points array contains coordinates for 
* the top-left corner of each node. We can use that and the width of the node to
* calculate intersection.
* Returns -1 when no intersect found
*/
export function getCollidedPointIndex({ x, y }: Point, points: Point[], nodeSize: number) {
  for (let i = 0; i < points.length; i ++) {
      if (x > points[i].x
          && x < points[i].x + nodeSize
          && y > points[i].y
          && y < points[i].y + nodeSize
      ) return i;
  }
  return -1;
}


// get distance between two points
export function getDistance(a: Point, b: Point) {
  return Math.hypot(b.y - a.y, b.x - a.x);
}


// get rotation degree between two points with a being treated as the x plane
export function getAngle(a: Point, b: Point) {
  return Math.atan2(b.y - a.y, b.x - a.x)
}

/**
 * Returns an array of node numbers that have been intersected by a line from indexFrom to indexTo.
 * patternSize in this case is the size of a single axis of the pattern lock (by number of nodes). Pattern
 * is always square, so all sides are equal
 */
export function getIntersectedNodes(indexFrom: number, indexTo: number, patternSize: number): number[] {

  // get point coordinates from indexes
  const fromPoint: Point = { x: indexFrom % patternSize, y: Math.floor(indexFrom / patternSize) };
  const toPoint: Point = { x: indexTo % patternSize, y: Math.floor(indexTo / patternSize) };

  // there are three possible intersects available:
  // 1. Horizontal
  if (fromPoint.y === toPoint.y) {
    // get the absolute distance between two points on x axis
    const diff = Math.abs(fromPoint.x - toPoint.x);
    // if difference is small, ignore
    if (diff <= 1) return [];
    const points: number[] = [];

    for (let i = 0; i < diff; i++) {
      const point = (fromPoint.y * patternSize) + i + Math.min(fromPoint.x, toPoint.x);
      points.push(point);
    }
    return points;
  }

  // 2. Vertical
  if (fromPoint.x === toPoint.x) {

    // get the absolute distance between two points on y axis
    const diff = Math.abs(fromPoint.y - toPoint.y);
    // if difference is small, ignore
    if (diff <= 1) return [];
    const points: number[] = [];

    for (let i = 0; i < diff; i++) {
      const point = ((i + Math.min(fromPoint.y, toPoint.y)) * patternSize) + fromPoint.x;
      points.push(point);
    }
    return points;
  }

  // 3. Diagonal
  const xDiff = Math.abs(fromPoint.x - toPoint.x);
  const yDiff = Math.abs(fromPoint.y - toPoint.y);
  if (xDiff === yDiff && xDiff > 0) {
    // get diagonal direction
    const dirX = toPoint.x - fromPoint.x > 0 ? 1 : -1;
    const dirY = toPoint.y - fromPoint.y > 0 ? 1 : -1;

    const points: number[] = [];
    for (let i = 1; i < yDiff; i++) {
      const point = (((i * dirY) + fromPoint.y) * patternSize) + (i * dirX) + fromPoint.x;
      points.push(point);
      return points;
    }
  }

  return [];
}