import {AsyncLoadResult} from "../../../shared/abstractStore";
import {useLazyQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {mainApiConfig} from "../../../configure/mainApiConfig";
import React from "react";
import {ClaimQueryOptions} from "./useMyClaim";
import {claimDeliveryDetails} from "../generated/claimDeliveryDetails";


export function useClaimDeliveryDetails(options: ClaimQueryOptions): AsyncLoadResult<claimDeliveryDetails> {

    const [load, {loading, error, data, refetch}] = useLazyQuery(
        gql`
            query claimDeliveryDetails($id: String!){
                item: claim(id: $id){
                    id,
                    dpddeliveryNumber,
                    lastDeliveryAttemptDate,
                    nextDeliveryAttemptDate,
                    deliveryAddress,
                    deliveryTrackingDetails,
                    deliveryCourier,
                    dpdcollectionNumber,
                    lastCollectionAttemptDate,
                    nextCollectionAttemptDate,
                    collectionAddress,
                    collectionTrackingDetails,
                    dpdcollectionNumber,
                    collectionCourier: courier,
                    dispatchEvent: claimEvent(name: "Returned to End User") {
                        eventDate,
                        name
                    },
                }
            }
        `, {
            variables: {id: options.id},
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted){
                    options.onCompleted(data);
                }
            }
        }
    )

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    const refresh = React.useCallback(
        async () => {!refetch ? await load() : await refetch();},
        [refetch, load]);

    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);

}
