import './pinEntry.scss';

import React from "react";
import {Button, FormGroup, Input, Label, Spinner} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ButtonAsync} from "reactstrap-buttonasync";

export interface PinEntryProps {
    onChangeCallback: (passcode: string) => void
    passcode?: string,
    isLoading: boolean,
}


/**
 * Form group containing input specialised for numeric PIN entry
 */
export function PinEntry(props: PinEntryProps) {
    const {onChangeCallback, isLoading = false, passcode: initialPasscode = ""} = props;

    const [passcode, setPasscode] = React.useState<string>(initialPasscode);
    const [showPassword, setShowPassword] = React.useState<boolean>(false);

    const toggleShowPassword = () => setShowPassword(!showPassword);

    function focusPrevious(currentName: string){
        // focus on change
        const currentFieldNumber = Number.parseInt(currentName.split("-")[1]);
        // if value is empty, focus on previous element
        const pinElement = document.getElementsByName(`pin-${currentFieldNumber - 1}`)

        if (pinElement.length){
            pinElement[0].focus();
        }
    }

    function focusNext(currentName: string){
        // focus on change
        const currentFieldNumber = Number.parseInt(currentName.split("-")[1]);
        // if value is empty, focus on previous element
        const pinElement = document.getElementsByName(`pin-${currentFieldNumber + 1}`)[0]

        // focus on next input if there is one
        if (pinElement) {
            pinElement.focus();
        } else {
            // otherwise lose focus on current element
            const currentElement = document.getElementsByName(currentName)[0];
            currentElement.blur();
        }
    }

    function pinKeyEvent(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === "Backspace" || e.key === "Delete"){
            e.currentTarget.value = "";

            const newPassCode = passcode.substring(0, passcode.length - 1);
            setPasscode(newPassCode);
            onChangeCallback(newPassCode);

            // if value is empty, focus on previous element
            focusPrevious(e.currentTarget.name)
        }
    }

    // pin change event
    function onPinInputChanged(e: React.ChangeEvent<HTMLInputElement>){
        if(!isNaN(Number(e.currentTarget.value))){
            if (e.currentTarget.value !== "") {

                const newPassCode = passcode + e.currentTarget.value;
                setPasscode(newPassCode);
                onChangeCallback(newPassCode);

                // if value was put in, focus on next element
                focusNext(e.currentTarget.name)
            }
        } else {
            // cancel input otherwise
            e.currentTarget.value = "";
        }
    }

    // pin focus event
    function onPinAreaClick(e: React.MouseEvent<HTMLElement>){
        // clear passcode
        setPasscode("");
        onChangeCallback(passcode);

        const firstPinInput = document.getElementsByName("pin-1")[0];
        if (firstPinInput){
            firstPinInput.focus();
        }
    }

    return (
        <FormGroup className={"pin-entry"}>
            <div className={"password-label-area"}>
                <Label>{"Please enter PIN"}</Label>
                <Button type={"button"}
                        className={"show-password"}
                        color={"secondary"}
                        onClick={toggleShowPassword}
                >
                    {showPassword ? (
                        <>
                            <FontAwesomeIcon icon={"eye-slash"}/>
                            {"  Hide PIN"}
                        </>
                    ) : (
                        <>
                            <FontAwesomeIcon icon={"eye"}/>
                            {"  Show PIN"}
                        </>
                    )}
                </Button>
            </div>
            <div className={"pin-submit-area"} onClick={onPinAreaClick}>
                <Input type={showPassword ? "number" : "password"}
                       className={"pincode-input"}
                       inputMode={"numeric"}
                       name={"pin-1"}
                       pattern={"[0-9]*"}
                       autoComplete={"off"}
                       maxLength={1}
                       onChange={onPinInputChanged}
                       onKeyDown={pinKeyEvent}
                       value={passcode[0] ?? ""}
                />
                <Input type={showPassword ? "number" : "password"}
                       className={"pincode-input"}
                       inputMode={"numeric"}
                       name={"pin-2"}
                       pattern={"[0-9]*"}
                       autoComplete={"off"}
                       maxLength={1}
                       onChange={onPinInputChanged}
                       onKeyDown={pinKeyEvent}
                       onMouseDown={e => e.preventDefault()}
                       value={passcode[1] ?? ""}
                />
                <Input type={showPassword ? "number" : "password"}
                       className={"pincode-input"}
                       inputMode={"numeric"}
                       name={"pin-3"}
                       pattern={"[0-9]*"}
                       autoComplete={"off"}
                       maxLength={1}
                       onChange={onPinInputChanged}
                       onKeyDown={pinKeyEvent}
                       value={passcode[2] ?? ""}
                />
                <Input type={showPassword ? "number" : "password"}
                       className={"pincode-input"}
                       inputMode={"numeric"}
                       name={"pin-4"}
                       pattern={"[0-9]*"}
                       autoComplete={"off"}
                       maxLength={1}
                       onChange={onPinInputChanged}
                       onKeyDown={pinKeyEvent}
                       value={passcode[3] ?? ""}
                />
                <Input type={showPassword ? "number" : "password"}
                       className={"pincode-input"}
                       inputMode={"numeric"}
                       name={"pin-5"}
                       pattern={"[0-9]*"}
                       autoComplete={"off"}
                       maxLength={1}
                       onChange={onPinInputChanged}
                       onKeyDown={pinKeyEvent}
                       value={passcode[4] ?? ""}
                />
                <Input type={showPassword ? "number" : "password"}
                       className={"pincode-input"}
                       inputMode={"numeric"}
                       name={"pin-6"}
                       pattern={"[0-9]*"}
                       autoComplete={"off"}
                       maxLength={1}
                       onChange={onPinInputChanged}
                       onKeyDown={pinKeyEvent}
                       value={passcode[5] ?? ""}
                />
                <Input type={showPassword ? "number" : "password"}
                       className={"pincode-input"}
                       inputMode={"numeric"}
                       name={"pin-7"}
                       pattern={"[0-9]*"}
                       autoComplete={"off"}
                       maxLength={1}
                       onChange={onPinInputChanged}
                       onKeyDown={pinKeyEvent}
                       value={passcode[6] ?? ""}
                />
                <Input type={showPassword ? "number" : "password"}
                       className={"pincode-input"}
                       inputMode={"numeric"}
                       name={"pin-8"}
                       autoComplete={"off"}
                       maxLength={1}
                       onChange={onPinInputChanged}
                       onKeyDown={pinKeyEvent}
                       value={passcode[7] ?? ""}
                />
            </div>
            <Button type={"button"}
                className={"show-password-mobile"}
                color={"secondary"}
                onClick={toggleShowPassword}
            >
                {showPassword ? (
                    <>
                        <FontAwesomeIcon icon={"eye-slash"} />
                        {"  Hide PIN"}
                    </>
                ) : (
                        <>
                            <FontAwesomeIcon icon={"eye"} />
                            {"  Show PIN"}
                        </>
                    )}
            </Button>
            <ButtonAsync className={"submit-button"}
                         type={"submit"}
                         color={"primary"}
                         isExecuting={isLoading}
                         executingChildren={<><Spinner size={"sm"}/></>}>
                {"Submit"}
            </ButtonAsync>
        </FormGroup>
    )
}