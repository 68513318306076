import {AsyncActionStatus} from "../../../shared/abstractStore";
import {useAsyncCallback} from "react-use-async-callback";
import {EndUserService, SendMessageRequest} from "../../account/EndUserService";


/**
 * Callback to send a message to Greenbridge
 */
export function useSendMessageCallback(): [(message: SendMessageRequest) => Promise<void>, AsyncActionStatus] {
  const claimService = new EndUserService();

  const [action, { isExecuting, errors }] = useAsyncCallback(async (message: SendMessageRequest) => {
    return await claimService.sendMessage(message);
  }
    , [claimService]);

  return [action, { isExecuting, errors }];
}

