import './messageComponent.scss';

import React from "react";
import {Alert, Button, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {StyledModal} from "../shared/StyledModal";
import {useMediaQuery} from "react-responsive";
import {useMarkMessageAsRead} from "../../api/main/claims/useMarkMessageAsRead";
import ReactHtmlParser from 'react-html-parser';
import moment from "moment";

export interface MessageProps {
    messageId?: string,
    subject?: string,
    body?: string,
    dateSent?: Date,
    read: boolean,
    active: boolean,
    isLoading?: boolean
}

export function MessageComponent(props: MessageProps){
    const {subject = "", body = "", dateSent = null, read = false, active = true, messageId} = props;
    const [modal, setModal] = React.useState<boolean>(false);
    const [messagePreview, setMessagePreview] = React.useState<string>("");
    const [subjectPreview, setSubjectPreview] = React.useState<string>("");

    const [markAsReadCallback] = useMarkMessageAsRead();

    const isSmallScreen = useMediaQuery({maxWidth: "768px"});


    const stripHtlm = (html: string): string => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || ""
    }
    
    const trimMessage = React.useCallback(() => {
            const maxChars = isSmallScreen ? 40 : 70;
            const trimmed = stripHtlm(body).trim();
            return trimmed.length > maxChars ? trimmed.slice(0, maxChars) + "..." : trimmed;
    }, [body, isSmallScreen]);

    const trimSubject = React.useCallback(() => {
        const maxChars = isSmallScreen ? 20 : 30;
        return subject.length > maxChars ? subject.slice(0, maxChars) + "..." : subject;
    }, [subject, isSmallScreen])

    const markAsRead = React.useCallback(async () => {
        if (messageId && !read) {
            await markAsReadCallback({id: messageId, read: true, active: active});
        }
     }, [messageId, read, active, markAsReadCallback]);

     const parsedHtml = React.useMemo(() => {
        try {
            return ReactHtmlParser(body);
        } catch {
            return <Alert color={"danger"}>Html message could not be parsed!</Alert>
        }
     }, [body]);

    React.useEffect(() => {
        setSubjectPreview(trimSubject());
        setMessagePreview(trimMessage())
    }, []);
  
    const toggleModal = () => {
        if (modal && !read){
            // mark message as read when closing modal
            markAsRead();
        }
        setModal(!modal);
    }
    return (
        <div className={`message ${!read ? "unread" : ""}`} onClick={toggleModal}>
            <div className={"message-subject"}>
                <span>{subjectPreview}</span>
            </div>
            <div className={"message-preview"}>{messagePreview}</div>
            <div className={"button-container"}>
                <Button className={"modal-button"}><FontAwesomeIcon icon={"ellipsis-v"}/></Button>
            </div>
            <StyledModal className={"message-modal"} toggle={toggleModal} isOpen={modal}>
                <ModalHeader toggle={toggleModal}>
                    {subject}
                </ModalHeader>
                <ModalBody>
                    {parsedHtml}
                </ModalBody>
                <ModalFooter>
                    {dateSent && (
                        moment(dateSent).format("DD/MM/YYYY hh:mm")
                    )}
                </ModalFooter>
            </StyledModal>
        </div>
    )
}