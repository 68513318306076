import './claimCurrentStateSummary.scss';

import React from "react";
import {LoadingIndicator} from "./LoadingIndicator";


export const ClaimCurrentStateSummary = (props: {claimState?: string, isLoading?: boolean}) => {
    const {claimState = "", isLoading = false} = props;


    return (
        <div className={"claim-current-state-summary"}>
            {isLoading ? (
                <div className={"loading-wrapper"}>
                    <LoadingIndicator size={"sm"}/>
                </div>
            ) : (
                <div className={`current-state`}>{claimState}</div>
            )}
        </div>
    )
}