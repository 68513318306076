import React from "react";
import './myReference.scss';
import {ConditionalFragment} from "../../shared/conditionalFragment";
import {LoadingIndicator} from "./LoadingIndicator";

export const MyReference = (props: {reference?: string, isLoading?: boolean}) => {

    const {reference, isLoading = false} = props;

    return (
        <div className={"my-reference"}>
            <div className={"wrapper"}>
                <div className={"reference-header"}>My Reference</div>
                <ConditionalFragment showIf={isLoading}>
                    <LoadingIndicator size={"sm"}/>
                </ConditionalFragment>
                {!isLoading && (
                    <div className={"reference"}>{reference ?? ""}</div>
                )}
            </div>
        </div>
    )
}
