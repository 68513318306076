import './claimProgressStatus.scss'

import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ConditionalFragment} from "../../shared/conditionalFragment";
import {LoadingIndicator} from "./LoadingIndicator";

export interface ClaimProgressStatusProps {
    status?: string,
    isLoading?: boolean,
    size?: 'sm' | 'md' | 'lg'
}

export const ClaimProgressStatus = (props: ClaimProgressStatusProps) => {
    const {status, size = "md", isLoading = false} = props;

    let statusClass;
    switch (status){
        case "Finished":
            statusClass = 'finished'
            break;
        case "In Progress":
            statusClass = "in-progress"
            break;
        case "On Hold":
            statusClass = "on-hold"
            break;
        default:
            statusClass = "in-progress"
    }

    return (
        <div className={`progress-status ${size} ${statusClass}`}>
            <ConditionalFragment showIf={isLoading && !status}>
                <LoadingIndicator fullWidth size={"sm"}/>
            </ConditionalFragment>
            <div className={"wrapper"}>
                <FontAwesomeIcon icon="circle"/>
                <span className={"status-text"}>{status}</span>
            </div>
        </div>
    )
}