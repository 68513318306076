import './icloudModal.scss';
import React from "react";
import {StyledModal} from "../shared/StyledModal";
import {Button, ModalBody, ModalHeader} from "reactstrap";
import {useHistory} from "react-router";
import {useCurrentClaimId} from "../../api/main/claims/useCurrentClaimId";
import {useSubmitIcloudStatus} from "../../api/main/dataFormDatas/useSubmitIcloudStatus";
import {AlertOnErrors} from "../../shared/alertOnErrors";
import {LoadingIndicator} from "./LoadingIndicator";
import {ConditionalFragment} from "../../shared/conditionalFragment";


export function ICloudModal(props: {onSubmitCallback?: (data?: string) => void}){

    const claimId = useCurrentClaimId();
    const [submitIcloudStatus, {errors, isExecuting}] = useSubmitIcloudStatus()

    // modal and tab states
    const history = useHistory();
    const [modal, setModal] = React.useState<boolean>(history.location.pathname.includes("/remove-icloud"));

    // toggle modals and tabs
    const toggleModal = () => setModal(!modal);

    // submit icloud status
    const submit = (claimId: string, iCloudOff: boolean) => {
        submitIcloudStatus({claimId: claimId, iCloudOff: iCloudOff});
        setModal(false);
    }

    return (
        <StyledModal className={"icloud-entry"}
                     isOpen={modal}
                     toggle={toggleModal}
                     onClosed={() => {
                         history.push(history.location.pathname.replace("/remove-icloud", ""));
                         if (props.onSubmitCallback) {
                             props.onSubmitCallback();
                         }
                     }}
        >
            <ModalHeader>
                {"Confirm iCloud Removal"}
                <p>{"In order to progress your claim, you need to remove iCloud lock from your device."}</p>
                <p>
                    {"Need help? "}
                    <a
                        target={"_blank"}
                        rel="noopener noreferrer"
                        href={"https://www.greenbridge.co.uk/guides/smartphones/how-to-remove-your-icloud-account/"}>
                        {"Click here"}
                    </a>
                    {" to view our iCloud Removal guide"}
                </p>
            </ModalHeader>
            <ModalBody>
                <AlertOnErrors errors={[errors]}/>
                <div className={"button-container"}>
                    <Button color={"primary"} onClick={e => {
                        if (claimId){
                            submit(claimId, true);
                        }} }>{"iCloud Removed"}</Button>
                    <Button color={"secondary"} onClick={e => {
                        if (claimId){
                            submit(claimId, false);
                        }} }>{"Unable to Remove iCloud"}</Button>
                </div>
                <ConditionalFragment showIf={isExecuting}>
                    <LoadingIndicator fullWidth size={"sm"}/>
                </ConditionalFragment>
            </ModalBody>
        </StyledModal>
    )
}
