import './notification.scss';

import React from "react";
import {Container} from "reactstrap";
import {useClaimSummary} from "../../api/main/claims/useClaimSummary";


export interface ClaimStateNotificationProps {
    claimId?: string,
    stateName?: string,
    children?: React.ReactNode
}

export const ClaimStateNotification = (props: ClaimStateNotificationProps) => {

    const {data: {item}, isLoading} = useClaimSummary({id: props.claimId});
    if (isLoading && !item){
        return (<></>);
    }

    // show message when claim is sent to external repair
    if (item?.state?.systemName === props.stateName) {
        return (
            <Container fluid>
                <div className={"notification state-notification"}>
                    <div className={"notification-message"}>
                        {props.children}
                    </div>
                </div>
            </Container>
        )
    }
    if (!props.claimId && props.children) {
        return (
            <Container fluid>
                <div className={"notification persistent"}>
                    <div className={"notification-message"}>
                        {props.children}
                    </div>
                </div>

            </Container>
        )
    }

    return (
        <></>
    )
}
