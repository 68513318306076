/**
 * URIs of common claim types.
 */
export const ClaimTypes = {
    /**
     * The URI for a claim that specifies the actor.
     */
    actor: 'http://schemas.xmlsoap.org/ws/2009/09/identity/claims/actor',
    /**
     * The URI for a claim that specifies the postal code of an entity.
     */
    postalCode: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/postalcode',
    /**
     * The URI for a claim that specifies an RSA key.
     */
    rsa: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/rsa',
    /**
     * The URI for a claim that specifies a security identifier (SID).
     */
    sid: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid',
    /**
     * The URI for a claim that specifies a service principal name (SPN) track.
     */
    spn: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/spn',
    /**
     * The URI for a claim that specifies the state or province in which an entity resides.
     */
    stateOrProvince: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/stateorprovince',
    /**
     * The URI for a claim that specifies the street address of an entity.
     */
    streetAddress: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/streetaddress',
    /**
     * The URI for a claim that specifies the surname of an entity.
     */
    surname: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname',
    /**
     * The URI for a claim that identifies the system entity.
     */
    system: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/system',
    /**
     * The URI for a claim that specifies a thumbprint. A thumbprint is a globally unique SHA-1 hash of an X.509 certificate.
     */
    thumbprint: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/thumbprint',
    /**
     * The URI for a claim that specifies a user principal name (UPN).
     */
    upn: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/upn',
    /**
     * The URI for a claim that specifies a URI.
     */
    uri: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/uri',
    /**
     * The URI for a claim that specifies the webpage of an entity.
     */
    webpage: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/webpage',
    /**
     *     The URI for a claim that specifies the alternative phone number of an entity.
     */
    otherPhone: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/otherphone',
    /**
     * The URI for a claim that specifies the name of an entity.
     */
    nameIdentifier: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier',
    /**
     * The URI for a claim that specifies the name of an entity.
     */
    name: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
    /**
     * The URI for a claim that specifies the mobile phone number of an entity.
     */
    mobilePhone: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/mobilephone',
    /**
     * The URI for a claim that specifies the anonymous user.
     */
    anonymous: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/anonymous',
    /**
     * The URI for a claim that specifies details about whether an identity is authenticated.
     */
    authentication: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication',
    /**
     * The URI for a claim that specifies an authorization decision on an entity.
     */
    authorizationDecision: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authorizationdecision',
    /**
     * The URI for a claim that specifies the country/region in which an entity resides,
    */
    country: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country',
    /**
     * The URI for a claim that specifies the date of birth of an entity.
     */
    dateOfBirth: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/dateofbirth',
    /**
     * The URI for a claim that specifies a deny-only security identifier (SID) for
     * an entity. A deny-only SID denies the specified entity to a securable object.
     */
    denyOnlySid: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/denyonlysid',
    /**
     * The URI for a claim that specifies the email address of an entity.
     */
    email: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
    /**
     * The URI for a claim that specifies the gender of an entity.
     */
    gender: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/gender',   
    /**
     * The URI for a claim that specifies the given name of an entity.
     */
    givenName: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname',
    /**
     * The URI for a claim that specifies a hash value.
     */
    hash: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/hash',
    /**
     * The URI for a claim that specifies the home phone number of an entity.
     */
    homePhone: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/homephone',
    /**
     * The URI for a claim that specifies the locale in which an entity resides.
     */
    locality: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/locality',
    /**
     * The URI for a claim that specifies the DNS name associated with the computer
     * name or with the alternative name of either the subject or issuer of an X.509
     * certificate.
     */
    dns: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/dns',
    /**
     * The URI for an X.500 distinguished name track, such as the subject of an X.509
     * Public Key Certificate or an entry identifier in a directory services Directory
     * Information Tree.
     */
    x500DistinguishedName: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/x500distinguishedname',
}

/**
 * URIs of common Microsoft specific track types.
 */
export const MicrosoftClaimTypes = {
    /**
     * The URI for a claim that specifies the primary group SID of an entity.
     */
    primaryGroupSid: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/primarygroupsid',
    /**
     * The URI for a claim that specifies the primary SID of an entity.
     */
    primarySid: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/primarysid',
    /**
     * The URI for a claim that specifies the role of an entity.
     */
    role: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',
    /**
     * The URI for a claim that specifies a serial number.
     */
    serialNumber: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/serialnumber',
    /**
     * The URI for a claim that specifies the user data.
     */
    userData: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata',
    /**
     * The URI for a claim that specifies the version.
     */
    version: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/version',
    /**
     * The URI for a claim that specifies the Windows domain account name of an entity.
     */
    windowsAccountName: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/windowsaccountname',
    /**
     * The URI for a claim that specifies the Windows device.
     */
    windowsDeviceClaim: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/windowsdeviceclaim',
    /**
     * The URI for a claim that specifies the Windows group SID of the device.
     */
    windowsDeviceGroup: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/windowsdevicegroup',
    /**
     * 
     */
    windowsFqbnVersion: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/windowsfqbnversion',
    /**
     * 
     */
    windowsSubAuthority: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/windowssubauthority',
    /**
     * The URI for a claim that specifies the instant at which an entity was authenticated,
    */
    authenticationInstant: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/authenticationinstant',
    /**
     * The URI for a claim that specifies the method with which an entity was authenticated,
    */
    authenticationMethod: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/authenticationmethod',
    /**
     * The URI for a claim that specifies the cookie path.
     */
    cookiePath: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/cookiepath',
    /**
     * The URI for a claim that specifies the deny-only primary group SID on an entity,
     * A deny-only SID denies the specified entity to a securable object.
     */
    denyOnlyPrimaryGroupSid: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/denyonlyprimarygroupsid',
    /**
     * The URI for a claim that specifies the deny-only primary SID on an entity.
     * A deny-only SID denies the specified entity to a securable object.
     */
    denyOnlyPrimarySid: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/denyonlyprimarysid',
    /**
     * 
     */
    windowsUserClaim: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/windowsuserclaim',
    /**
     * The URI for a claim that specifies the Windows deny-only group SID of the device,
     */
    denyOnlyWindowsDeviceGroup: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/denyonlywindowsdevicegroup',
    /**
     */
    dsa: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/dsa',
    /**
     * 
     */
    expiration: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/expiration',
    /**
     * 
     */
    expired: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/expired',
    /**
     * The URI for a claim that specifies the SID for the group of an entity.
     */
    groupSid: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/groupsid',
    /**
     * 
     */
    isPersistent: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/ispersistent',
}
