import React from "react";
import { useCurrentClaimId } from "../../api/main/claims/useCurrentClaimId";
import { TrackedClaimContainer } from "../shared/TrackedClaimContainer";
import { ClaimStatus } from "./ClaimStatus";
import "./myClaim.scss";
import { MyDevice } from "./MyDevice";



export function MyClaim() {

    const claimId = useCurrentClaimId();
    return (
        <TrackedClaimContainer className={"my-claim"}>
            <ClaimStatus claimId={claimId} />
            <MyDevice claimId={claimId} />
        </TrackedClaimContainer>
    )
}