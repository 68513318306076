import './historyItem.scss';

import React from "react";
import {DateView} from "../shared/dateView";
import {LoadingIndicator} from "../shared/LoadingIndicator";
import moment from "moment";


export interface HistoryItemProps {
    placeholder?: boolean,
    text?: string,
    date?: moment.Moment
}


export const HistoryItem = (props: HistoryItemProps) => {

    const {placeholder = false, text, date} = props;

    return (
        <table className={`history-item ${placeholder ? "placeholder" : ""}`}>
            <tbody>
            <tr>
                <td>
                    <div className={"history-text"}>
                        <div className={"text"}>{text}</div>
                    </div>
                </td>
                <td>
                    <div className={"date-wrapper"}>

                        {placeholder ? (
                            <LoadingIndicator size={"sm"}/>
                        ) : (
                            <DateView placeholder={placeholder} date={date} showTime={true} size={"sm"}/>
                        )}
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    )
}


