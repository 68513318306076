import gql from "graphql-tag";
import {quoteDetailFieldsFragment} from "./QuoteDetail";


export const quoteFieldsFragment = gql`
    fragment quoteFields on Quote {
        id,
        claimId,
        total,
        vat,
        quoteDetails {
            ...quoteDetailFields
        }
    }
    
    ${quoteDetailFieldsFragment}
`