import './claimNextStageSummary.scss';

import * as React from "react";
import {Col, Container, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {LoadingIndicator} from "./LoadingIndicator";

export function ClaimNextStageSummary(props: {nextStage?: string, readyByDate?: string, isLoading?: boolean}) {
    const {nextStage, readyByDate, isLoading = false} = props;

    return (
        <Container className={"claim-next-stage-summary"}>
            <Row>
                <Col xs={6}>
                    <div className={"header"}>
                        <FontAwesomeIcon icon={"caret-down"}/>
                        <span className={"next-stage"}>Next Stage</span>
                    </div>
                    {isLoading ? (
                        <LoadingIndicator size={"sm"} fullWidth/>
                    ) : (
                        <div className={"value"}>{nextStage}</div>
                    )}
                </Col>
                <Col xs={6}>
                    <div className={"header"}>Est. Completion</div>
                    {isLoading ? (
                        <LoadingIndicator size={"sm"} fullWidth/>
                    ) : (
                        <div className={"value"}>{readyByDate}</div>
                    )}
                </Col>
            </Row>
        </Container>

    )
}
