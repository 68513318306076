import React, { useState } from "react";
import { TrackedClaimContainer } from "../shared/TrackedClaimContainer";
import { PageSection } from "../../shared/pageSection/PageSection";
import { useCurrentClaimId } from "../../api/main/claims/useCurrentClaimId";
import moment from "moment";
import { useStripe, useElements, PaymentElement, Elements } from "@stripe/react-stripe-js";
import { ConditionalFragment } from "../../shared/conditionalFragment";
import { useMyClaimDetails } from "../../api/main/claims/useMyClaimDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { loadStripe } from '@stripe/stripe-js/';
import { stripeConfig } from "../../configure/stripeConfig";
import { myClaimSupportingDetailsQuery_item } from "../../api/main/generated/myClaimSupportingDetailsQuery";
import { Guid } from "guid-string";

// Make sure to call `loadStripe` outside of a component�s render to avoid recreating the `Stripe` object on every render.
const stripePromise = loadStripe(stripeConfig.publishableKey);

export function StripeExcessContainer() {

    const [clientSecret, setClientSecret] = React.useState("");
    const claimId = useCurrentClaimId();



    React.useEffect(() => {
        if (!claimId) {
            return;
        }

            // Create PaymentIntent as soon as the page loads
            fetch("/api/create-payment-intent/Create", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ item: { id: claimId } }),
            })
                .then((res) => res.json())
                .then((info) => setClientSecret(info.clientSecret));
    }, [claimId]);

    const options = {
        clientSecret
    };

    return (
        <TrackedClaimContainer className={"excess-payment"}>
            {clientSecret && (
                <Elements stripe={stripePromise} options={options}>
                    <ExcessPayment claimId={claimId} />
                </Elements>
            )}
        </TrackedClaimContainer>
    );

}

export interface excessPaymentProps {
    claimId: string | undefined,
    paymentStatus?: string | undefined,
}

export const ExcessPayment = (props: excessPaymentProps) => {

    const {
        claimId,
        paymentStatus: initialPaymentStatus,
    } = props;

    var { data, isLoading, errors } = useMyClaimDetails({ id: claimId })

    const stripe = useStripe();
    const elements = useElements();

    const [paymentStatus, setPaymentStatus] = useState<string | undefined>(initialPaymentStatus);
    const [paymentError, setPaymentError] = useState<string | undefined>();

    const handleSubmit = async () => {
        if (!stripe || !elements || !data.item?.id) {
            // stop from being null
            return;
        }

        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements: elements as any,
            redirect: "always", // Will only redirect if the payment method requires it.
            confirmParams: {
                return_url: new URL(`/excess-payment-completed/${claimId}`, new URL(window.location.href)).toString(),
            }
        });

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.log(result.error.message);
            setPaymentError(result.error.message);
            setPaymentStatus('error');
        }
        //else if (result.paymentIntent.amount === (data.item.policyExcessAmount * 100)) {
        //    // Only want to progress if the numbers match

        //    // Your customer will be redirected to your `return_url`.
        //    // For some payment methods like iDEAL, your customer will be redirected to an intermediate site first to authorize the payment, then redirected to the `return_url`.

        //    // log a succesful payment
        //    setPaymentStatus('success');
        //}
    };

    return (
        <PageSection className={"excess-payment"}>
            <ConditionalFragment showIf={paymentStatus === 'succeeded' || !!data.item?.excessPaymentTaken}>
                <div style={{ textAlign: "center" }}>
                    <div style={{ fontSize: 50 }}>
                        <FontAwesomeIcon icon="check-circle" style={{ color: "#00B79B" }} />
                        {" Excess has been paid"}
                    </div>
                    <p>Excess was paid on: {moment(data.item?.excessTakenDate).format('DD-MM-YYYY')}</p>
                </div>
            </ConditionalFragment>

            <ConditionalFragment showIf={paymentStatus === 'error'}>
                <div style={{ textAlign: "center" }}>
                <div style={{ fontSize: 50 }}>
                    <FontAwesomeIcon icon="exclamation" style={{ color: "red" }} />
                        {"Error taking excess payment"}
                </div>
                <p>Message from your payment provider: {paymentError}</p>
                </div>
            </ConditionalFragment>

            { /*Unsure if the excess amount will ever be 0 live but we will deal with it just incase*/}
            <ConditionalFragment showIf={data.item?.policyExcessAmount === 0}>
                <div style={{ textAlign: "center" }}>
                    <div style={{ fontSize: 50 }}>
                        <p>No excess to be paid</p>
                    </div>
                </div>
            </ConditionalFragment>

            <ConditionalFragment showIf={!!stripe && !!data.item && data.item.policyExcessAmount > 0 && !data.item.excessPaymentTaken}>
                <div style={{ textAlign: "center" }}>
                    <div style={{ fontSize: 50 }}>
                        <p>Excess due: &pound;{data.item?.policyExcessAmount.toFixed(2)}</p>
                    </div>
                </div>
                <form onSubmit={e => {
                    e.preventDefault();

                    handleSubmit();
                }}>
                    <PaymentElement />
                    <button style={{marginTop: 10, paddingTop: '12px', paddingBottom: '12px', paddingLeft: '12px', paddingRight: '12px', }} disabled={!stripe}>Pay now</button>
                </form>
            </ConditionalFragment>
        </PageSection>
    );

};