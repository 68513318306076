import './pageSection.scss';
import React from "react";

export interface PageSectionProps {
    children?: React.ReactNode,
    className?: string
}

export const PageSection = (props: PageSectionProps) => {
    const {className, children} = props;

    return (
        <div className={`page-section ${className ?? ""}`}>
            {children}
        </div>
    )
}