import {useLazyQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {eventHistoryFieldsFragement} from "../models/EventHistory";
import {mainApiConfig} from "../../../configure/mainApiConfig";
import {AsyncLoadOptions, AsyncLoadResult} from "../../../shared/abstractStore";
import React from "react";
import {claimEventsQuery} from "../generated/claimEventsQuery";


export interface ClaimEventsOptions extends AsyncLoadOptions {
    id?: string,
    filterName?: string
}


export function useClaimEventHistories(options: ClaimEventsOptions): AsyncLoadResult<claimEventsQuery> {
    // query data
    const [load, {data, loading, error, refetch}] = useLazyQuery(
        gql`
            query claimEventsQuery ($id: String!, $name: String) {
                claim (id: $id) {
                    id,
                    events (eventName: $name) {
                        ...eventHistoryFields
                    }
                }
            }
            ${eventHistoryFieldsFragement}
        `, {
            variables: {
                id: options.id,
                name: options.filterName
            },

            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted){
                    options.onCompleted(data);
                }
            }
        }
    )

    // run the query unless lazy loading is specified
    React.useEffect(() => {
        if (!options.lazy){
            load();
        }
    }, [options.lazy, load])

    // return the results
    const refresh = React.useCallback(async () => {
            !refetch? await load() : await refetch();
    }, [refetch, load])

    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error])
}