import {useMutation} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {claimFieldsFragment} from "../models/Claim";
import {AsyncActionStatus} from "../../../shared/abstractStore";
import {useAsyncCallback} from "react-use-async-callback";
import {ChangeAddressClaimInput} from "../generated/globalTypes";
import {changeDeliveryAddress, changeDeliveryAddressVariables} from "../generated/changeDeliveryAddress";


/**
 * Get a callback to update delivery address for the claim
 */
export function useUpdateDeliveryAddress(): [(model: ChangeAddressClaimInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, {error}] = useMutation<changeDeliveryAddress, changeDeliveryAddressVariables>(
        gql`
            mutation changeDeliveryAddress($model: ChangeAddressClaimInput!) {
                changeDeliveryAddress(model: $model){
                    ...claimFields
                }
            }
            ${claimFieldsFragment}
        `);

        // Wrap the mutation in a standard callback.

        const [action, {isExecuting, errors: actionErrors}] = useAsyncCallback(
            async (model: ChangeAddressClaimInput) => {
                const result = await mutationAction({variables: {model: model}});
                if (result.errors && result.errors.length){
                    throw result.errors;
                }
            }, [mutationAction]
        )

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}