import {DataFormDatasOptions} from "./useClaimDataFormDatas";
import {dataFormDatasQuery_items} from "../generated/dataFormDatasQuery";
import React from "react";
import {DataFormData} from "../types/types";

export interface MatchingDataFormDatasOptions extends DataFormDatasOptions {
    filterCallback: (item: dataFormDatasQuery_items | null, n?: number) => boolean
}



/**
 * Find matching data form data
 */
export function useMatchingDataFormDatasList(
    datas: (DataFormData|null)[] | undefined,
    filterCallback: (data: DataFormData) => boolean): DataFormData[] | undefined {

    return React.useMemo(() => {
        if (!datas?.length) return;

        const res: DataFormData[] = [];
        for (let data of datas){
            if (data !== null) {
                if (filterCallback(data)){
                    res.push(data);
                }
            }
        }
        return res;

    }, [filterCallback, datas])
}

/**
 * Find matching data form data into a dictionary
 */
export function useMatchingDataFormDatas(
    datas: (DataFormData|null)[] | undefined,
    filterCallback: (data: DataFormData) => boolean): {[key: string]: DataFormData} | undefined {

    return React.useMemo(() => {
        if (!datas?.length) return {};

        const res: {[key: string]: DataFormData} = {};
        for (let data of datas){
            if (data !== null) {
                if (filterCallback(data)){
                    res[data.name] = data;
                }
            }
        }
        return res;

    }, [filterCallback, datas])
}