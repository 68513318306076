import {AsyncLoadResult} from "../../../shared/abstractStore";
import {useLazyQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {mainApiConfig} from "../../../configure/mainApiConfig";
import React from "react";
import {portalNotificationFieldsFragment} from "../models/PortalNotification";
import {claimNotificationQuery} from "../generated/claimNotificationQuery";
import {ClaimQueryOptions} from "./useMyClaim";


export function useClaimNotification(options: ClaimQueryOptions)
    : AsyncLoadResult<claimNotificationQuery> {
    // create a query

    const [load, {loading, error, data, refetch}] = useLazyQuery(
        gql`
            query claimNotificationQuery($claimId: String) {
                item : claim (id: $claimId) {
                    id,
                    number
                    notification {
                        ...portalNotificationFields
                    }
                }
            }
            ${portalNotificationFieldsFragment}
        `,
        {
            variables: {claimId: options.id},
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if(options.onCompleted){
                    options?.onCompleted(data);
                }
            }
        }
    )

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}
