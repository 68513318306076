import * as React from 'react';
import {NavItem, NavLink} from 'reactstrap';
import {NavLinkTrackActive} from '../shared/NavLinkTrackActive';
import {useCurrentClaimId} from "../../api/main/claims/useCurrentClaimId";
import {useValidateClaimId} from "../../api/main/claims/useValidateClaimId";
import { useMyClaimDetails } from '../../api/main/claims/useMyClaimDetails';

/**
 * Primary navigation menu for the app.
 */
export const Navigation = () => {

    const claimId = useCurrentClaimId();
    const { called, claimId: validatedId } = useValidateClaimId(claimId);
    var { data: { item: claim } } = useMyClaimDetails({ id: claimId })

    if (called && (!validatedId || validatedId !== claimId)) {
        return <></>
    }

    return (
        <>
            <NavItem>
                <NavLink tag={NavLinkTrackActive} to={`/my-claim/${claimId}`}>
                    <> {"My Claim"}</>
                </NavLink>
            </NavItem>
            {
                (claim?.policyExcessAmount ?? 0) > 0 ? (
                    <NavItem>
                        <NavLink tag={NavLinkTrackActive} to={`/excess-payment/${claimId}`}>
                            <> {"Excess Payment"}</>
                        </NavLink>
                    </NavItem>
                ) : null
            }
            <NavItem>
                <NavLink tag={NavLinkTrackActive} to={`/report/${claimId}`}>
                    <> {"Report"}</>
                </NavLink>
            </NavItem>
            {/*<NavItem>
                <NavLink tag={NavLinkTrackActive} to={`/photos/${claimId}`}>
                    <> {"Photos"}</>
                </NavLink>
            </NavItem>*/}
            <NavItem>
                <NavLink tag={NavLinkTrackActive} to={`/my-details/${claimId}`}>
                    <> {"My Details"}</>
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={NavLinkTrackActive} to={`/messages/${claimId}`}>
                    <> {"Messages"}</>
                </NavLink>
            </NavItem>
          {/*  <NavItem>
                <NavLink tag={NavLinkTrackActive} to={`/history/${claimId}`}>
                    <> {"History"}</>
                </NavLink>
            </NavItem>*/}
            {/*<NavItem>
                <NavLink tag={NavLinkTrackActive} to={`/quote/${claimId}`}>
                    <> {"Quote"}</>
                </NavLink>
            </NavItem>*/}
            <NavItem>
                <NavLink tag={NavLinkTrackActive} to={`/my-delivery/${claimId}`}>
                    <> {"Track My Parcel"}</>
                </NavLink>
            </NavItem>
            
        </>
    );
};
