import {useMutation} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {AsyncActionStatus} from "../../../shared/abstractStore";
import {useAsyncCallback} from "react-use-async-callback";
import {PortalMessageFlagsInput} from "../generated/globalTypes";
import {messageFieldsFragment} from "../models/Message";
import {markMessageAsRead, markMessageAsReadVariables} from "../generated/markMessageAsRead";


/**
 * Get a callback to update delivery address for the claim
 */
export function useMarkMessageAsRead(): [(model: PortalMessageFlagsInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, {error}] = useMutation<markMessageAsRead, markMessageAsReadVariables>(
        gql`
            mutation markMessageAsRead($model: PortalMessageFlagsInput!) {
                updateMessageFlags(model: $model){
                    ...messageFields
                }
            }
            ${messageFieldsFragment}
        `);

        // Wrap the mutation in a standard callback.

        const [action, {isExecuting, errors: actionErrors}] = useAsyncCallback(
            async (model: PortalMessageFlagsInput) => {
                const result = await mutationAction({variables: {model: model}});
                if (result.errors && result.errors.length){
                    throw result.errors;
                }
            }, [mutationAction]
        )

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}