import './layout.scss';

import * as React from 'react';
import {Header} from './Header';
import {Footer} from './Footer';

import {RouteEntry} from '../../shared/routes';
import {LoadingIndicator} from "../shared/LoadingIndicator";
import {Helmet} from "react-helmet-async";

export interface LayoutProps {
    appRoutes: Array<RouteEntry>,
    children: React.ReactNode
}

export const Layout = (props: LayoutProps) => {



    return (
        <div className="app">
            <Header />
            <div className="app-body">
                <main className="main">
                    <React.Suspense fallback={<LoadingIndicator fullWidth size={"sm"}/>}>
                        {props.children}
                        {/*LiveChat iframe:*/}
                        <Helmet script={[{
                            type: "text/javascript",
                            innerHTML: `window.__lc = window.__lc || {};
                                        window.__lc.license = 11364017;
                                        window.__lc.visitor = {
                                            name: '',
                                            email: ''
                                        };
                                        (function() {
                                            var lc = document.createElement('script'); lc.type = 'text/javascript'; lc.async = true;
                                            lc.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.livechatinc.com/tracking.js';
                                            var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(lc, s);
                                        })();`
                        }]}/>
                    </React.Suspense>
                </main>
            </div>
            <Footer />
        </div>
    );
};
