import gql from "graphql-tag";


export const messageFieldsFragment = gql`
    
    fragment messageFields on PortalMessage {
        id,
        claimId,
        dateSent,
        subject,
        body,
        read,
        active
       
    }
`