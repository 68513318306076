import './loginContainer.scss';

import * as React from 'react';
import { Container, ContainerProps, ListGroupItem, ListGroup } from 'reactstrap';
import {useMediaQuery} from "react-responsive";

export interface MainContainerProps extends ContainerProps {
}

/**
 * Input that is hooked up for searching as the user types, by introducing an appropriate delay before raising the onSearch event.
 */
export const LoginContainer = (props: MainContainerProps) => {
    const { className, children, ...rest } = props;
    const isSmallScreen = useMediaQuery({maxWidth: "768px"});

    return (
        <Container className={"login-area"}>
            <Container className={`login-container ${className ?? ''}`} {...rest}>
                {children}
                <div className={"footer-container"}>
                    <ListGroup horizontal={!isSmallScreen} className={"footer"}>
                        <ListGroupItem><span style={{fontWeight: 500}}>{"© Greenbridge Technology"}</span></ListGroupItem>
                        {/*<ListGroupItem tag={"a"} target='_blank' href={"#"} >{"Terms & Conditions"}</ListGroupItem>*/}
                        <ListGroupItem tag={"a"} target='_blank' href={"http://www.greenbridge.co.uk/privacy-policy/"}>{"Privacy Notice"}</ListGroupItem>
                    </ListGroup>
                </div>
            </Container>
        </Container>
    );
};
