import React from "react";
import {DateView} from "../shared/dateView";
import moment from "moment";
import {ResponsiveProgress} from "../shared/ResponsiveProgress";

export interface DateProgressProps {
    step?: string,
    description?: string,
    dateStart?: moment.Moment,
    dateEnd?: moment.Moment,
    progressAmount?: number,
    placeholder?: boolean,
    isLoading?: boolean,
}

export function DateProgress(props: DateProgressProps){

    const {
        dateEnd,
        dateStart,
        progressAmount,
        step = "",
        description = "",
        placeholder = false,
        isLoading = false,
    } = props;


    let progressValue = progressAmount !== undefined ? progressAmount : 0;

    // if we are not given a progress value, calculate progress based on date difference
    if (!progressAmount && dateStart && dateEnd){

        let total = dateEnd.diff(dateStart, 'days');
        let currentProgress = moment().diff(dateStart, 'days');

        // if progress is negative (progress hasn't started yet), show 0
        // otherwise calculate progress
        progressValue = currentProgress >= 0 ?
            moment() > dateEnd ? 1 : (currentProgress / total) * 100
            : 0;
    }

    return (
        <div className={`progress-step ${placeholder ? "placeholder" : ""}`}>
            <ResponsiveProgress bar
                                maxWidth={"991px"}
                                value={placeholder ? 0 : progressValue}
                                aria-valuemin={0}
                                aria-valuemax={100}
            />
            <div className={"progress-details"}>
                <DateView placeholder={placeholder} isLoading={isLoading} date={dateStart ? moment(dateStart) : undefined} showNoDataIcon={true}/>
                <div className={"progress-description"}>
                    <div className={"state"}>{placeholder ? "" : step}</div>
                    <div className={"description"}>{placeholder ? "" : description}</div>
                </div>
            </div>
        </div>
    )
}