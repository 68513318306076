import gql from "graphql-tag";


export const dataFormDataFieldsFragment = gql`
    fragment dataFormDataFields on DataFormData {
        id,
        sharedDataKey,
        name,
        decimalValue,
        intValue,
        stringValue,
        textValue,
    }
        
`