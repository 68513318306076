import './loadingComponent.scss';
import React from "react";
import {ConditionalFragment} from "../../shared/conditionalFragment";
import {LoadingIndicator} from "./LoadingIndicator";
import Container from "reactstrap/lib/Container";


export interface HeaderLoadingIndicatorProps {
    isLoading?: boolean
    children?: React.ReactNode
}

/**
 * Displays a loading indicator next to children its child
 */
export function LoadingComponent(props: HeaderLoadingIndicatorProps){

    const {isLoading = false, children} = props;

    return (
        <Container className={"loading-component"}>
            {children}
            <ConditionalFragment showIf={isLoading}>
                <LoadingIndicator size={"sm"}/>
            </ConditionalFragment>
        </Container>
    )
}