import './messages.scss';

import React from "react";
import {TrackedClaimContainer} from "../shared/TrackedClaimContainer";
import {PageSection} from "../../shared/pageSection/PageSection";
import {Alert, Badge, Form, FormGroup, Label, Spinner} from "reactstrap";
import {ValidatedInput} from "pojo-validator-reactstrap";
import {useChanges} from "../../shared/useChanges";
import {ButtonAsync} from "reactstrap-buttonasync";
import {useValidatorCallback} from "pojo-validator-react";
import {ConditionalFragment} from "../../shared/conditionalFragment";
import {useClaimMessages} from "../../api/main/claims/useClaimMessages";
import {useCurrentClaimId} from "../../api/main/claims/useCurrentClaimId";
import {MessageComponent} from "./MessageComponent";
import {LoadingIndicator} from "../shared/LoadingIndicator";
import {claimMessagesQuery_claim_messages} from "../../api/main/generated/claimMessagesQuery";
import moment from "moment";
import {useSendMessageCallback} from '../../api/main/messages/useSendMessageCallback';
import {AlertOnErrors} from '../../shared/alertOnErrors';
import {GDPRConsentFormPart} from "../shared/GDPRConsentFormPart";

interface MessageModel {
    subject?: string,
    message?: string,
    gdprConsent?: boolean,
}


export function Messages(){

    const claimId = useCurrentClaimId();

    /* received messages handling */
    // get existing messages
    const {data: {claim}, isLoading: messagesLoading, errors: messageLoadErrors} = useClaimMessages({id: claimId});
    const [unreadCount, setUnreadCount] = React.useState<number>(0);
    const [unreadMessages, setSortedMessages] = React.useState<(claimMessagesQuery_claim_messages | null)[]>([]);

    // sort and count unread
    React.useEffect(() => {

        if (claim?.messages) {

            setUnreadCount(claim.messages.filter(m => m && !m.read).length);

            claim.messages.sort((a, b) => {
                if (!a?.dateSent || !b?.dateSent) return 0;
                let momentA = moment(a.dateSent);
                let momentB = moment(b.dateSent);
                return momentA.isBefore(momentB)
                    ? 1
                    : momentA.isAfter(momentB) ? -1 : 0;
            });

            setSortedMessages(claim.messages)
        }
    }, [claim])


    /*  new message handling  */
    const {model, change} = useChanges<MessageModel>({message: "", subject: "", gdprConsent: false})
    const [isSent, setIsSent] = React.useState<boolean>(false);
    const [sendMessageCallback, {isExecuting: isSending, errors: sendMessageErrors}] = useSendMessageCallback();

    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            subject: () => !model.subject ? 'Please enter topic' : '',
            message: () => !model.message ? 'Please enter message' : '',
            gdprConsent: () => !model.gdprConsent ? 'Please tick this box if you consent to our Privacy Notice' : ''
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    const sendMessage = React.useCallback(async () => {

        if (!validate()) return;

        // send message
        if (model.subject && model.message && claimId) {
            change({ message: "", subject: "" });
            setIsSent(true);
            await sendMessageCallback({ subject: model.subject, message: model.message, claimId: claimId });

        }

    }, [validate, claimId, change, model, sendMessageCallback])


    return (
        <TrackedClaimContainer className={"messages"}>
            <PageSection>
                <h1>{"Messages"}{claim?.messages && (<Badge color={"primary"}>{unreadCount}</Badge>)}</h1>
                <div className={"recent-messages"}>
                    <AlertOnErrors errors={[messageLoadErrors]}/>
                    <ConditionalFragment showIf={messagesLoading}>
                        <LoadingIndicator fullWidth/>
                    </ConditionalFragment>
                    {unreadMessages.length ? (
                        unreadMessages.map((m, i) => {
                            if (!m) return null;
                            return <MessageComponent key={i} body={m.body} subject={m.subject} dateSent={m.dateSent} read={m.read} messageId={m.id} active={m.active ?? false}/>
                        })
                    ) : (
                        <span className={"no-recent-msg"}>{"No recent messages"}</span>
                    )}
                </div>
            </PageSection>
            <PageSection className={"email-section"}>
                <ConditionalFragment showIf={isSent}>
                    <Alert>{"Thank you, we've received your message."}</Alert>
                </ConditionalFragment>
                {/*<AlertOnErrors errors={[sendMessageErrors]}/>*/}
                <Form onSubmit={e => {e.preventDefault(); sendMessage();}}>
                    <FormGroup>
                        <Label>{"Email Greenbridge"}</Label>
                        <ValidatedInput
                            type={"text"}
                            name={"subject"}
                            value={model.subject}
                            placeholder={"Enter Topic"}
                            validationErrors={validationErrors["subject"]}
                            onChange={e => change({subject: e.currentTarget.value})}
                        />
                        <ValidatedInput
                            type={"textarea"}
                            name={"message"}
                            value={model.message}
                            placeholder={"Enter Message"}
                            validationErrors={validationErrors["message"]}
                            onChange={e => change({message: e.currentTarget.value})}
                        />
                    </FormGroup>
                    <GDPRConsentFormPart
                        checked={model.gdprConsent}
                        validationErrors={validationErrors["gdprConsent"]}
                        onChange={(checked) => change({gdprConsent: checked})}
                        onBlur={(e) => validate("gdprConsent")}
                    />
                    <ButtonAsync type={"submit"} color={"primary"} isExecuting={isSending}
                                 executingChildren={<><Spinner size={"sm"} />{"Sending..."}</>}>
                        {"Send Message"}
                    </ButtonAsync>
                </Form>
            </PageSection>
        </TrackedClaimContainer>
    )

}
